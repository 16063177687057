export default defineNuxtPlugin((nuxtApp) => {
  const ce = new CustomEvent('afterclick', { detail: 'test' })

  const emitEvent = async (event: PointerEvent) => {
    const { checkClickPopup } = useFacePunch()
    const el = event.currentTarget as HTMLElement
    // 有事件埋点，先判断事件埋点，通过后在判断是否登录
    const isAuth = el.binding?.modifiers?.auth
    const isStop = el.binding?.modifiers?.stop

    const { isLogin } = useUserStore()
    const showAuthModal = isAuth && !isLogin

    // 判断是否展示打脸图
    if (checkClickPopup()) {
      event.stopPropagation()
      event.preventDefault()
      return
    }

    // 判断是否展示登录弹窗
    if (showAuthModal) {
      event.stopPropagation()
      event.preventDefault()
      return openModalRoute({ modal: 'ath', tab: 'signin' })
    }

    if (isStop) {
      event.stopPropagation()
      event.preventDefault()
    }

    el.dispatchEvent(ce)
  }

  nuxtApp.vueApp.directive('face-punch-event', {
    mounted(el: any, binding) {
      el.binding = binding
      el.dataset.facePunchEvent = binding.value
      el.addEventListener('click', emitEvent, true)
      // el.addEventListener('afterclick', (e) => {
      //   console.log('afterclick', e?.detail)
      // }, false)
    },

    // 绑定元素的父组件卸载后调用
    unmounted(el) {
      el.removeEventListener('click', emitEvent, true)
    },
  })
})
