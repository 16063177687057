export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return
  if (to.meta.auth) {
    nextTick(() => {
      const router = useRouter()
      const { userInfo, isLogin } = storeToRefs(useUserStore())
      if (userInfo.value?.userId && !isLogin.value) {
        if (!from.meta.auth) {
          openModalRoute({ modal: 'ath', tab: 'signin' })
        } else {
          router.push('/')
        }
      }
    })
  }
})
