export const useSiteMsgStore = defineStore('siteMsgStore', () => {
  const userSiteMsg: Ref<IInteriorMailListResp> = ref({})
  const hasNewMsg = computed(() => userSiteMsg.value?.details?.filter((item) => item.isRead !== '1').length > 0)
  async function initSiteMsg() {
    const response = await request(userInfoInteriorMailList({ pageIndex: 1, pageSize: 30 }))
    userSiteMsg.value = response
  }
  return { hasNewMsg, userSiteMsg, initSiteMsg }
})

// 悬浮窗弹窗
export const useFloatWindowStore = defineStore('floatWindowStore', () => {
  const SHOW_MAX_FLOAT_WINDOW_COUNT = 1
  const SHOW_MAX_PREDOMINANT_WINDOW_COUNT = 1

  const floatWindowList = ref<Array<INotifyBasicPopupWindowInfo & { triggerTime: number }>>([])
  const predominantWindowList = ref<Array<IPopupWindowPredominantDetail & { triggerType: string, triggerTime: number }>>([])

  const initPredominantWindowList = async () => {
    const { $timer } = useNuxtApp()
    const response = await request(basicPopupWindowPredominant({}))
    predominantWindowList.value = (response.list || []).slice(0, SHOW_MAX_PREDOMINANT_WINDOW_COUNT).map((item) => ({ ...item, triggerType: 'predominant', triggerTime: $timer.now }))
  }

  const removePredominantWindow = (index: number) => {
    predominantWindowList.value.splice(index, 1)
  }

  const addFloatWindow = (floatWindow: INotifyBasicPopupWindowInfo) => {
    const { $timer } = useNuxtApp()
    
    // 判断是否存在已展示的相同的类型浮窗
    const existFloatWindow = floatWindowList.value.findIndex((item) => item.triggerType === floatWindow.triggerType)
    
    if (existFloatWindow >= 0) {
      // 更新悬浮窗
      floatWindowList.value[existFloatWindow] = { ...floatWindow, triggerTime: $timer.now }
    } else if (floatWindowList.value.length < SHOW_MAX_FLOAT_WINDOW_COUNT) {
      // 判断是否超过最大展示数
      floatWindowList.value.push({ ...floatWindow, triggerTime: $timer.now })
    }
  }

  const removeFloatWindow = (index: number) => {
    floatWindowList.value.splice(index, 1)
  }

  const emitExitDepositEvent = () => {
    request(basicPopupWindowTrig({ typ: 1 }))
  }

  const emitExitGameEvent = () => {
    request(basicPopupWindowTrig({ typ: 2 }))
  }

  return {
    predominantWindowList,
    initPredominantWindowList,
    removePredominantWindow,
    
    floatWindowList,
    addFloatWindow,
    removeFloatWindow,
    emitExitDepositEvent,
    emitExitGameEvent,
  }
})

// 全局公告
export const useGlobalNotificationStore = defineStore('globalNotificationStore', () => {
  const { $timer } = useNuxtApp()
  const globalNotification: Ref<IBulletinConfResp> = ref({})

  const msg = computed(() => globalNotification.value?.content || '')
  const interval = computed(() => Number(globalNotification.value?.intervalMinute || 10))

  const isValid = computed(() => {
    const now = $timer.now
    const { startTime, endTime } = globalNotification.value || {}
    return msg && Number(startTime) <= now && now <= Number(endTime)
  })

  async function initGlobalNotification() {
    const response = await request(basicBulletinConf({}))
    globalNotification.value = response || {}
  }

  return { msg, interval, isValid, initGlobalNotification }
})