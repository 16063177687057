export const useGameType = () => {
  const { $i18n } = useNuxtApp()
  const GAME_TYPE_KEY = {
    slots: 'slots',
    live: 'live',
    fishing: 'fishing',
    poker: 'poker',
    sports: 'sports',
    lottery: 'lottery',
    virtualsports: 'virtualsports',
  }

  const GAME_TYPE_KEY_MAP = {
    [GameType_const.GameTypeSlotGames]: GAME_TYPE_KEY.slots,
    [GameType_const.GameTypeLiveCasino]: GAME_TYPE_KEY.live,
    [GameType_const.GameTypeFishing]: GAME_TYPE_KEY.fishing,
    [GameType_const.GameTypeBoardGame]: GAME_TYPE_KEY.poker,
    [GameType_const.GameTypeSports]: GAME_TYPE_KEY.sports,
    [GameType_const.GameTypeLottery]: GAME_TYPE_KEY.lottery,
    [GameType_const.GameTypeVirtualSports]: GAME_TYPE_KEY.virtualsports,
  }

  const GAME_TYPE_ID_MAP = {
    [GAME_TYPE_KEY.slots]: GameType_const.GameTypeSlotGames,
    [GAME_TYPE_KEY.live]: GameType_const.GameTypeLiveCasino,
    [GAME_TYPE_KEY.fishing]: GameType_const.GameTypeFishing,
    [GAME_TYPE_KEY.poker]: GameType_const.GameTypeBoardGame,
    [GAME_TYPE_KEY.sports]: GameType_const.GameTypeSports,
    [GAME_TYPE_KEY.lottery]: GameType_const.GameTypeLottery,
    [GAME_TYPE_KEY.virtualsports]: GameType_const.GameTypeVirtualSports,
  }

  const transGameTypeByKey = (t: ValueOf<typeof GAME_TYPE_KEY>) => {
    const types = {
      [GAME_TYPE_KEY.sports]: $i18n.t('sportsText'),
      [GAME_TYPE_KEY.live]: $i18n.t('liveCasinoText'),
      [GAME_TYPE_KEY.fishing]: $i18n.t('fishing-game-type'), //捕鱼
      [GAME_TYPE_KEY.slots]: $i18n.t('slotsText'), //老虎机
      [GAME_TYPE_KEY.virtualsports]: $i18n.t('virtualSportsText'), //虚拟体育
      [GAME_TYPE_KEY.poker]: $i18n.t('title.poker'), // 棋牌游戏
      [GAME_TYPE_KEY.lottery]: $i18n.t('lottery-game-type'), // 彩票
    }
    return types[t]
  }

  const transGameType = (t: ValueOf<typeof GameType_const> | number) => {
    const types = {
      [GameType_const.GameTypeSports]: $i18n.t('sportsText'),
      [GameType_const.GameTypeLiveCasino]: $i18n.t('liveCasinoText'),
      [GameType_const.GameTypeFishing]: $i18n.t('fishing-game-type'), //捕鱼
      [GameType_const.GameTypeSlotGames]: $i18n.t('slotsText'), //老虎机
      [GameType_const.GameTypeVirtualSports]: $i18n.t('virtualSportsText'), //虚拟体育
      [GameType_const.GameTypeBoardGame]: $i18n.t('title.poker'), // 棋牌游戏
      [GameType_const.GameTypeLottery]: $i18n.t('lottery-game-type'), // 彩票
    }
    return types[t]
  }

  const transGameIcon = (t: ValueOf<typeof GameType_const>) => {
    const icons = {
      [GameType_const.GameTypeSports]: 'games/sports',
      [GameType_const.GameTypeLiveCasino]: 'games/glive',
      [GameType_const.GameTypeFishing]: 'games/fishing',
      [GameType_const.GameTypeSlotGames]: 'games/slots',
      [GameType_const.GameTypeVirtualSports]: 'games/virtual-sports',
      [GameType_const.GameTypeBoardGame]: 'games/board',
      [GameType_const.GameTypeLottery]: 'games/lottery',
    }
    return icons[t]
  }
  return { GAME_TYPE_KEY, GAME_TYPE_KEY_MAP, GAME_TYPE_ID_MAP, transGameTypeByKey, transGameType, transGameIcon }
}
