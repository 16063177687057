export const useMenuStore = defineStore('menuStore', () => {
  const isDesktopOpenMenu = ref(true)
  const openDesktopMenu = () => {
    isDesktopOpenMenu.value = true
  }
  const closeDesktopMenu = () => {
    isDesktopOpenMenu.value = false
  }
  const toggleDesktopMenu = () => {
    isDesktopOpenMenu.value = !isDesktopOpenMenu.value
  }

  const isMobileOpenMenu = ref(false)
  const openMobileMenu = () => {
    isMobileOpenMenu.value = true
  }
  const closeMobileMenu = () => {
    isMobileOpenMenu.value = false
  }
  const toggleMobileMenu = () => {
    isMobileOpenMenu.value = !isDesktopOpenMenu.value
  }

  return {
    isDesktopOpenMenu,
    openDesktopMenu,
    closeDesktopMenu,
    toggleDesktopMenu,
    isMobileOpenMenu,
    openMobileMenu,
    closeMobileMenu,
    toggleMobileMenu,
  }
})
