// 节流函数
export function throttle(func: Function, time: number = 300, immediate?: boolean) {
  let timer: any | null = null
  if (process.server) {
    return (...args) => func(...args)
  }
  return (...args: any) => {
    if (timer) return

    if (immediate && !timer) {
      func(...args)
    }

    timer = setTimeout(() => {
      timer = null
      func(...args)
    }, time)
  }
}

// 防抖函数
export function debounce(fn: Function, wait = 50, immediate?: boolean) {
  let lastcall = 0
  let lastargs = null
  let timer: any = null
  if (process.server) {
    return (...args) => fn.apply(null, args)
  }
  return (...args: any[]) => {
    const now = Date.now()
    lastargs = args
    if (now - lastcall < wait) {
      if (timer) clearTimeout(timer)
      // immediate 为 true 表示第一次触发后执行
      // timer 为空表示首次触发
      if (immediate && !timer) {
        lastcall = now
        fn.apply(null, lastargs)
      }

      timer = setTimeout(() => {
        lastcall = now
        fn.apply(null, lastargs)
      }, wait)
    } else {
      lastcall = now
      fn.apply(null, args)
    }
  }
}

// 复制的方法
export async function copyText(text: string) {
  try {
    await navigator.clipboard.writeText(text)
  } catch (e) {
    const tag = document.createElement('textarea')
    tag.setAttribute('id', 'cp_hgz_input')
    tag.value = text
    document.getElementsByTagName('body')[0].appendChild(tag)
    const elm: any = document.getElementById('cp_hgz_input')
    elm.select()
    document.execCommand('copy')
    elm.remove()
    // eslint-disable-next-line no-empty
  }
}

export function sleep(milisec: number) {
  if (process.server) return Promise.resolve()
  return new Promise((resolve) => setTimeout(resolve, milisec))
}

// 判断是否安装pwa
export function isStandalone() {
  return navigator.standalone || window.matchMedia('(display-mode: standalone)').matches
}

// 获取url参数
export function getQueryParams() {
  return process.server
    ? {}
    : window.location.search
        .slice(1)
        .split('&')
        .filter(Boolean)
        .reduce((prev: Record<string, any>, curr) => {
          const [key, value] = curr.split('=')
          prev[key as string] = value
          return prev
        }, {})
}

// 转义游戏url，防止第三方报错
export function encodeGameUrl(url: string) {
  return encodeURI(url).replace(/%/g, '0X')
}

// 解析游戏url参数
export function decodeGameUrlComponent(str: string) {
  return decodeURIComponent(str.replace(/0X(20|22|23|25|26|28|9|2B|2C|2F|3A|3B|3C|3D|3E|3F|40|5C|7C)/g, '%$1'))
}

export function inputPositiveInteger(
  value: string,
  key: string,
  formData: any,
  strict: boolean = false,
  len?: number
): string {
  // const reg = /^[1-9]\d*$/
  // return reg.test(value)
  let replaceValue = value.replace(/[^\d]/g, '')

  // 是否严格模式，严格模式下，输入的数字必须大于等于0，00,01等无效
  if (strict) {
    if (replaceValue.startsWith('0') && replaceValue.length > 1) {
      replaceValue = replaceValue.slice(1)
    }
  }

  // 长度限制
  if (len && replaceValue.length > len) {
    replaceValue = replaceValue.slice(0, len)
  }

  if (isRef(formData)) {
    return (formData.value[key] = replaceValue)
  }

  if (isReactive(formData)) {
    return (formData[key] = replaceValue)
  }

  formData[key] = replaceValue
}
