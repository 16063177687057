export default defineNuxtPlugin({
  name: 'ANIMATE',
  parallel: true,
  async setup(nuxtApp) {
    nuxtApp.vueApp.directive('animate', {
      beforeUpdate(el, binding, vnode, prevVnode) {
        if (!!binding.value && binding.value !== binding.oldValue) {
          el?.classList?.remove(binding.arg)
          requestAnimationFrame(() => {
            requestAnimationFrame(() => {
              el?.classList?.add(binding.arg)
            })
          })
        }
      },
    })
  },
})
