export default defineNuxtPlugin({
  name: 'TRACK',
  async setup(nuxtApp) {
    // 消费并上报注册/登录事件
    consumPageMsg('signin_success', (payload: any) => EventTrack('signin_success', payload))
    consumPageMsg('signup_success', (payload: any) => EventTrack('signup_success', payload))

    nuxtApp.vueApp.directive('track', {
      // 在绑定元素的父组件
      // 及他自己的所有子节点都挂载完成后调用
      mounted(el: any, binding, _vnode, _prevVnode) {
        el.ontrack = (_ev: any) => {
          const ev = binding.arg
          if (ev) {
            EventTrack(binding.arg as any, binding.value || null)
          }
        }
        el.addEventListener('click', el.ontrack, false)
      },

      // 绑定元素的父组件卸载后调用
      unmounted(el, binding, _vnode, _prevVnode) {
        el.ontrack && el.removeEventListener('click', el.ontrack, false)
      },
    })
  },
})
