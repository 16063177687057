export default defineNuxtPlugin({
  name: 'VOLCE',
  parallel: true,
  async setup(nuxtApp) {
    // const config = useRuntimeConfig()
    nuxtApp.vueApp.directive('volce', {
      // 在绑定元素的父组件
      // 及他自己的所有子节点都挂载完成后调用
      mounted(el: any, binding, vnode, prevVnode) {
        el.oncollect = (ev: any) => {
          if (binding.arg && !binding.modifiers.leave) {
            // window.collectEvent(binding.arg, binding.value)
            // AFReport(binding.arg, binding.value);
          }
        }
        el.addEventListener('click', el.oncollect, false)
      },
      // 绑定元素的父组件卸载后调用
      unmounted(el, binding, vnode, prevVnode) {
        if (el.oncollect) {
          el.removeEventListener('click', el.oncollect, false)
        }
        if (binding.modifiers.leave) {
          // window.collectEvent(binding.arg, binding.value)
          // AFReport(binding.arg || "", binding.value);
        }
      },
    })
  },
})
