/**
 * 至少延迟delay毫秒后才返回结果
 * @param fn
 * @param delay
 * @returns
 */
export function useDelayResult<T extends Object>(fn: () => Promise<T>, delay: number = 300): Promise<T> {
  const awaitLock = ref(false)
  const result = ref<T>()

  return new Promise<T>(async (resolve, reject) => {
    const { stop } = useTimeoutFn(() => {
      awaitLock.value = true
      if (result.value) {
        resolve(result.value)
      }
    }, delay)

    try {
      const response = await fn()

      result.value = response

      if (awaitLock.value) {
        resolve(response)
      }
    } catch (err) {
      stop()
      reject(err)
    }
  })
}

/**
 * 必须等到delay毫秒后才开始更新loading状态
 * @param fn
 * @param delay
 */
export function useDelayLoading<T>(
  fn: () => Promise<T>,
  loading?: Ref<boolean> | boolean,
  delay: number = 300
): Promise<T|undefined> {
  const finished = ref(false)

  let loadingTarget: Ref<boolean> = ref(false)

  if (isRef(loading)) {
    loadingTarget = loading
  } else {
    const { isLoading } = storeToRefs(useGlobalStore())
    loadingTarget = isLoading
  }

  return new Promise<T>(async (resolve, reject) => {
    const { stop } = useTimeoutFn(() => {
      if (!finished.value) {
        loadingTarget.value = true
      }
    }, delay)

    try {
      const response = await fn()
      finished.value = true
      loadingTarget.value = false
      stop()
      resolve(response)
    } catch (err) {
      loadingTarget.value = false
      stop()
      reject(err)
    }
  })
}

/**
 * 异步请求组件loading状态管理
 */
const lazyCompentDelay = 300
const lazyCompentTimer = ref(null)

// 偶现 stopLazyCompoentLoading 没有被调用问题，防止页面一直处于loading状态，做一个超时处理，超时后自动停止loading
const lazyCompentTimeout = 15000
const lazyCompentTimeoutTimer = ref(null)

const clearLazyCompentTimer = () => {
  if (lazyCompentTimer.value) {
    clearTimeout(lazyCompentTimer.value)
    lazyCompentTimer.value = null
  }
}

const clearLazyCompentTimeoutTimer = () => {
  if (lazyCompentTimeoutTimer.value) {
    clearTimeout(lazyCompentTimeoutTimer.value)
    lazyCompentTimeoutTimer.value = null
  }
}

export function startLazyCompoentLoading() {
  // Log.info('startLazyCompoentLoading')
  const { isLoading } = storeToRefs(useGlobalStore())
  
  clearLazyCompentTimer()
  clearLazyCompentTimeoutTimer()

  lazyCompentTimer.value = setTimeout(() => {
    isLoading.value = true
  }, lazyCompentDelay)

  lazyCompentTimeoutTimer.value = setTimeout(() => {
    isLoading.value = false
  }, lazyCompentTimeout)
}

export function stopLazyCompoentLoading() {
  // Log.info('stopLazyCompoentLoading')
  const { isLoading } = storeToRefs(useGlobalStore())
  clearLazyCompentTimer()
  clearLazyCompentTimeoutTimer()
  isLoading.value = false
}
