import { default as _91id_93y4YCF1DOFyMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/[id].vue?macro=true";
import { default as index28CLkCDRZ9Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/index.vue?macro=true";
import { default as _91id_93Cnc0bH7muoMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/channel/[id].vue?macro=true";
import { default as deposit_46clientq5a62t9ldJMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/deposit.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_brz7t3lkqaifvohxu34hw6tm2y/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as download5MNHK28PyBMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/download.vue?macro=true";
import { default as _91url_93dWuynugyuHMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/m/[url].vue?macro=true";
import { default as _91_46_46_46keys_93Ny2Bo4TwUOMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/view/[...keys].vue?macro=true";
import { default as _91key_93N5idJVH6Q0Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/[key].vue?macro=true";
import { default as indexKa1PRtDvoZMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/index.vue?macro=true";
import { default as categoryQHsyoHcGGtMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category.vue?macro=true";
import { default as _91_46_46_46ids_93jW2ei0UbeuMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/catg/[...ids].vue?macro=true";
import { default as _91id_93IHNGnjoJpXMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/instr/[id].vue?macro=true";
import { default as _91key_93ZUNiTWPqTdMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/[key].vue?macro=true";
import { default as indexc0U0nZZmSzMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/index.vue?macro=true";
import { default as platformvaSJctfHtAMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform.vue?macro=true";
import { default as _91id_93TEvLMRaXEHMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/tag/[id].vue?macro=true";
import { default as _91id_93_46client4alvYUlnsDMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/gamev2/[id].client.vue?macro=true";
import { default as _91id_93D4V8HDr9omMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq/[id].vue?macro=true";
import { default as faqmHMTB52AP6Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq.vue?macro=true";
import { default as index6HAiSCcXeQMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/index.vue?macro=true";
import { default as goalo51JDll1jHMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/goal.vue?macro=true";
import { default as indexPEnWinOWwOMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/index.vue?macro=true";
import { default as interhO1p6vj6r6Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/inter.vue?macro=true";
import { default as rebateKX05c2r0BJMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/rebate.vue?macro=true";
import { default as income6qnksMHDrbMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income.vue?macro=true";
import { default as indexZXcQLepUjTMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/index.vue?macro=true";
import { default as rulew60OtyRKzsMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/rule.vue?macro=true";
import { default as bettingGM1sQYJJ4wMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/betting.vue?macro=true";
import { default as index5k9lol4BIfMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/index.vue?macro=true";
import { default as memberHFE9WECBCFMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/member.vue?macro=true";
import { default as sharelinkXAfnwP9aerMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/sharelink.vue?macro=true";
import { default as statementTT69unU9IrMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/statement.vue?macro=true";
import { default as team9vDAX7JurPMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team.vue?macro=true";
import { default as invite_46client0aUICSiadoMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite.client.vue?macro=true";
import { default as _91id_93npDiNXGKEeMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/provider/[id].vue?macro=true";
import { default as index2WjJfJjhjuMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/providers/index.vue?macro=true";
import { default as _91code_93VtT7p5hq3aMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/refer/[code].vue?macro=true";
import { default as indexTXS2NlauKJMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/search/index.vue?macro=true";
import { default as backpacktK5wBjjflnMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/backpack.vue?macro=true";
import { default as collectLO4h0rdLfWMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/collect.vue?macro=true";
import { default as depositTygoLnEfD1Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/deposit.vue?macro=true";
import { default as history9g0z6lcJI5Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/history.vue?macro=true";
import { default as indexeVLk0lHHI3Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/index.vue?macro=true";
import { default as _91id_93bJZ0p1ptwSMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/deposit/[id].vue?macro=true";
import { default as _91id_93nM8X654LTZMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/withdraw/[id].vue?macro=true";
import { default as _91id_93jKXiCD5S5SMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/agreement/[id].vue?macro=true";
import { default as indexFfKLr986CdMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/changepwd/index.vue?macro=true";
import { default as index9NENkI8f6KMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/feedback/index.vue?macro=true";
import { default as indexkW9hQGPFCBMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/index.vue?macro=true";
import { default as indexIiFkD60mVOMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/profile/index.vue?macro=true";
import { default as settingVHjMnHpYxQMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting.vue?macro=true";
import { default as transactioniDBhXjNkhKMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/transaction.vue?macro=true";
import { default as _91id_93TvfjTOMm0tMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/agreement/[id].vue?macro=true";
import { default as index35PkU6XSY5Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/index.vue?macro=true";
import { default as withdraw8UAInu9cC6Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/withdraw.vue?macro=true";
import { default as user_46clientgd2VCvxVt3Meta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user.client.vue?macro=true";
import { default as nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_brz7t3lkqaifvohxu34hw6tm2y/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57Fc } from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_brz7t3lkqaifvohxu34hw6tm2y/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91id_93y4YCF1DOFyMeta?.name ?? "activity-id___en",
    path: _91id_93y4YCF1DOFyMeta?.path ?? "/activity/:id()",
    meta: _91id_93y4YCF1DOFyMeta || {},
    alias: _91id_93y4YCF1DOFyMeta?.alias || [],
    redirect: _91id_93y4YCF1DOFyMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93y4YCF1DOFyMeta?.name ?? "activity-id___ur",
    path: _91id_93y4YCF1DOFyMeta?.path ?? "/ur/activity/:id()",
    meta: _91id_93y4YCF1DOFyMeta || {},
    alias: _91id_93y4YCF1DOFyMeta?.alias || [],
    redirect: _91id_93y4YCF1DOFyMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/[id].vue").then(m => m.default || m)
  },
  {
    name: index28CLkCDRZ9Meta?.name ?? "activity___en",
    path: index28CLkCDRZ9Meta?.path ?? "/activity",
    meta: index28CLkCDRZ9Meta || {},
    alias: index28CLkCDRZ9Meta?.alias || [],
    redirect: index28CLkCDRZ9Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: index28CLkCDRZ9Meta?.name ?? "activity___ur",
    path: index28CLkCDRZ9Meta?.path ?? "/ur/activity",
    meta: index28CLkCDRZ9Meta || {},
    alias: index28CLkCDRZ9Meta?.alias || [],
    redirect: index28CLkCDRZ9Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/activity/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Cnc0bH7muoMeta?.name ?? "channel-id___en",
    path: _91id_93Cnc0bH7muoMeta?.path ?? "/channel/:id()",
    meta: _91id_93Cnc0bH7muoMeta || {},
    alias: _91id_93Cnc0bH7muoMeta?.alias || [],
    redirect: _91id_93Cnc0bH7muoMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/channel/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Cnc0bH7muoMeta?.name ?? "channel-id___ur",
    path: _91id_93Cnc0bH7muoMeta?.path ?? "/ur/channel/:id()",
    meta: _91id_93Cnc0bH7muoMeta || {},
    alias: _91id_93Cnc0bH7muoMeta?.alias || [],
    redirect: _91id_93Cnc0bH7muoMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/channel/[id].vue").then(m => m.default || m)
  },
  {
    name: deposit_46clientq5a62t9ldJMeta?.name ?? "deposit___en",
    path: deposit_46clientq5a62t9ldJMeta?.path ?? "/deposit",
    meta: deposit_46clientq5a62t9ldJMeta || {},
    alias: deposit_46clientq5a62t9ldJMeta?.alias || [],
    redirect: deposit_46clientq5a62t9ldJMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/deposit.client.vue").then(m => m.default || m))
  },
  {
    name: deposit_46clientq5a62t9ldJMeta?.name ?? "deposit___ur",
    path: deposit_46clientq5a62t9ldJMeta?.path ?? "/ur/deposit",
    meta: deposit_46clientq5a62t9ldJMeta || {},
    alias: deposit_46clientq5a62t9ldJMeta?.alias || [],
    redirect: deposit_46clientq5a62t9ldJMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/deposit.client.vue").then(m => m.default || m))
  },
  {
    name: download5MNHK28PyBMeta?.name ?? "download___en",
    path: download5MNHK28PyBMeta?.path ?? "/download",
    meta: download5MNHK28PyBMeta || {},
    alias: download5MNHK28PyBMeta?.alias || [],
    redirect: download5MNHK28PyBMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/download.vue").then(m => m.default || m)
  },
  {
    name: download5MNHK28PyBMeta?.name ?? "download___ur",
    path: download5MNHK28PyBMeta?.path ?? "/ur/download",
    meta: download5MNHK28PyBMeta || {},
    alias: download5MNHK28PyBMeta?.alias || [],
    redirect: download5MNHK28PyBMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/download.vue").then(m => m.default || m)
  },
  {
    name: _91url_93dWuynugyuHMeta?.name ?? "game-m-url___en",
    path: _91url_93dWuynugyuHMeta?.path ?? "/game/m/:url()",
    meta: _91url_93dWuynugyuHMeta || {},
    alias: _91url_93dWuynugyuHMeta?.alias || [],
    redirect: _91url_93dWuynugyuHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/m/[url].vue").then(m => m.default || m)
  },
  {
    name: _91url_93dWuynugyuHMeta?.name ?? "game-m-url___ur",
    path: _91url_93dWuynugyuHMeta?.path ?? "/ur/game/m/:url()",
    meta: _91url_93dWuynugyuHMeta || {},
    alias: _91url_93dWuynugyuHMeta?.alias || [],
    redirect: _91url_93dWuynugyuHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/m/[url].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.name ?? "game-view-keys___en",
    path: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.path ?? "/game/view/:keys(.*)*",
    meta: _91_46_46_46keys_93Ny2Bo4TwUOMeta || {},
    alias: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.alias || [],
    redirect: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/view/[...keys].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.name ?? "game-view-keys___ur",
    path: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.path ?? "/ur/game/view/:keys(.*)*",
    meta: _91_46_46_46keys_93Ny2Bo4TwUOMeta || {},
    alias: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.alias || [],
    redirect: _91_46_46_46keys_93Ny2Bo4TwUOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/game/view/[...keys].vue").then(m => m.default || m)
  },
  {
    name: categoryQHsyoHcGGtMeta?.name ?? undefined,
    path: categoryQHsyoHcGGtMeta?.path ?? "/games/category",
    meta: categoryQHsyoHcGGtMeta || {},
    alias: categoryQHsyoHcGGtMeta?.alias || [],
    redirect: categoryQHsyoHcGGtMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category.vue").then(m => m.default || m),
    children: [
  {
    name: _91key_93N5idJVH6Q0Meta?.name ?? "games-category-key___en",
    path: _91key_93N5idJVH6Q0Meta?.path ?? ":key()",
    meta: _91key_93N5idJVH6Q0Meta || {},
    alias: _91key_93N5idJVH6Q0Meta?.alias || [],
    redirect: _91key_93N5idJVH6Q0Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/[key].vue").then(m => m.default || m)
  },
  {
    name: indexKa1PRtDvoZMeta?.name ?? "games-category___en",
    path: indexKa1PRtDvoZMeta?.path ?? "",
    meta: indexKa1PRtDvoZMeta || {},
    alias: indexKa1PRtDvoZMeta?.alias || [],
    redirect: indexKa1PRtDvoZMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: categoryQHsyoHcGGtMeta?.name ?? undefined,
    path: categoryQHsyoHcGGtMeta?.path ?? "/ur/games/category",
    meta: categoryQHsyoHcGGtMeta || {},
    alias: categoryQHsyoHcGGtMeta?.alias || [],
    redirect: categoryQHsyoHcGGtMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category.vue").then(m => m.default || m),
    children: [
  {
    name: _91key_93N5idJVH6Q0Meta?.name ?? "games-category-key___ur",
    path: _91key_93N5idJVH6Q0Meta?.path ?? ":key()",
    meta: _91key_93N5idJVH6Q0Meta || {},
    alias: _91key_93N5idJVH6Q0Meta?.alias || [],
    redirect: _91key_93N5idJVH6Q0Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/[key].vue").then(m => m.default || m)
  },
  {
    name: indexKa1PRtDvoZMeta?.name ?? "games-category___ur",
    path: indexKa1PRtDvoZMeta?.path ?? "",
    meta: indexKa1PRtDvoZMeta || {},
    alias: indexKa1PRtDvoZMeta?.alias || [],
    redirect: indexKa1PRtDvoZMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/category/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_46_46_46ids_93jW2ei0UbeuMeta?.name ?? "games-catg-ids___en",
    path: _91_46_46_46ids_93jW2ei0UbeuMeta?.path ?? "/games/catg/:ids(.*)*",
    meta: _91_46_46_46ids_93jW2ei0UbeuMeta || {},
    alias: _91_46_46_46ids_93jW2ei0UbeuMeta?.alias || [],
    redirect: _91_46_46_46ids_93jW2ei0UbeuMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/catg/[...ids].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46ids_93jW2ei0UbeuMeta?.name ?? "games-catg-ids___ur",
    path: _91_46_46_46ids_93jW2ei0UbeuMeta?.path ?? "/ur/games/catg/:ids(.*)*",
    meta: _91_46_46_46ids_93jW2ei0UbeuMeta || {},
    alias: _91_46_46_46ids_93jW2ei0UbeuMeta?.alias || [],
    redirect: _91_46_46_46ids_93jW2ei0UbeuMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/catg/[...ids].vue").then(m => m.default || m)
  },
  {
    name: _91id_93IHNGnjoJpXMeta?.name ?? "games-instr-id___en",
    path: _91id_93IHNGnjoJpXMeta?.path ?? "/games/instr/:id()",
    meta: _91id_93IHNGnjoJpXMeta || {},
    alias: _91id_93IHNGnjoJpXMeta?.alias || [],
    redirect: _91id_93IHNGnjoJpXMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/instr/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93IHNGnjoJpXMeta?.name ?? "games-instr-id___ur",
    path: _91id_93IHNGnjoJpXMeta?.path ?? "/ur/games/instr/:id()",
    meta: _91id_93IHNGnjoJpXMeta || {},
    alias: _91id_93IHNGnjoJpXMeta?.alias || [],
    redirect: _91id_93IHNGnjoJpXMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/instr/[id].vue").then(m => m.default || m)
  },
  {
    name: platformvaSJctfHtAMeta?.name ?? undefined,
    path: platformvaSJctfHtAMeta?.path ?? "/games/platform",
    meta: platformvaSJctfHtAMeta || {},
    alias: platformvaSJctfHtAMeta?.alias || [],
    redirect: platformvaSJctfHtAMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform.vue").then(m => m.default || m),
    children: [
  {
    name: _91key_93ZUNiTWPqTdMeta?.name ?? "games-platform-key___en",
    path: _91key_93ZUNiTWPqTdMeta?.path ?? ":key()",
    meta: _91key_93ZUNiTWPqTdMeta || {},
    alias: _91key_93ZUNiTWPqTdMeta?.alias || [],
    redirect: _91key_93ZUNiTWPqTdMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/[key].vue").then(m => m.default || m)
  },
  {
    name: indexc0U0nZZmSzMeta?.name ?? "games-platform___en",
    path: indexc0U0nZZmSzMeta?.path ?? "",
    meta: indexc0U0nZZmSzMeta || {},
    alias: indexc0U0nZZmSzMeta?.alias || [],
    redirect: indexc0U0nZZmSzMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: platformvaSJctfHtAMeta?.name ?? undefined,
    path: platformvaSJctfHtAMeta?.path ?? "/ur/games/platform",
    meta: platformvaSJctfHtAMeta || {},
    alias: platformvaSJctfHtAMeta?.alias || [],
    redirect: platformvaSJctfHtAMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform.vue").then(m => m.default || m),
    children: [
  {
    name: _91key_93ZUNiTWPqTdMeta?.name ?? "games-platform-key___ur",
    path: _91key_93ZUNiTWPqTdMeta?.path ?? ":key()",
    meta: _91key_93ZUNiTWPqTdMeta || {},
    alias: _91key_93ZUNiTWPqTdMeta?.alias || [],
    redirect: _91key_93ZUNiTWPqTdMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/[key].vue").then(m => m.default || m)
  },
  {
    name: indexc0U0nZZmSzMeta?.name ?? "games-platform___ur",
    path: indexc0U0nZZmSzMeta?.path ?? "",
    meta: indexc0U0nZZmSzMeta || {},
    alias: indexc0U0nZZmSzMeta?.alias || [],
    redirect: indexc0U0nZZmSzMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/platform/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93TEvLMRaXEHMeta?.name ?? "games-tag-id___en",
    path: _91id_93TEvLMRaXEHMeta?.path ?? "/games/tag/:id()",
    meta: _91id_93TEvLMRaXEHMeta || {},
    alias: _91id_93TEvLMRaXEHMeta?.alias || [],
    redirect: _91id_93TEvLMRaXEHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/tag/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93TEvLMRaXEHMeta?.name ?? "games-tag-id___ur",
    path: _91id_93TEvLMRaXEHMeta?.path ?? "/ur/games/tag/:id()",
    meta: _91id_93TEvLMRaXEHMeta || {},
    alias: _91id_93TEvLMRaXEHMeta?.alias || [],
    redirect: _91id_93TEvLMRaXEHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/games/tag/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93_46client4alvYUlnsDMeta?.name ?? "gamev2-id___en",
    path: _91id_93_46client4alvYUlnsDMeta?.path ?? "/gamev2/:id()",
    meta: _91id_93_46client4alvYUlnsDMeta || {},
    alias: _91id_93_46client4alvYUlnsDMeta?.alias || [],
    redirect: _91id_93_46client4alvYUlnsDMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/gamev2/[id].client.vue").then(m => m.default || m))
  },
  {
    name: _91id_93_46client4alvYUlnsDMeta?.name ?? "gamev2-id___ur",
    path: _91id_93_46client4alvYUlnsDMeta?.path ?? "/ur/gamev2/:id()",
    meta: _91id_93_46client4alvYUlnsDMeta || {},
    alias: _91id_93_46client4alvYUlnsDMeta?.alias || [],
    redirect: _91id_93_46client4alvYUlnsDMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/gamev2/[id].client.vue").then(m => m.default || m))
  },
  {
    name: faqmHMTB52AP6Meta?.name ?? "help-faq___en",
    path: faqmHMTB52AP6Meta?.path ?? "/help/faq",
    meta: faqmHMTB52AP6Meta || {},
    alias: faqmHMTB52AP6Meta?.alias || [],
    redirect: faqmHMTB52AP6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93D4V8HDr9omMeta?.name ?? "help-faq-id___en",
    path: _91id_93D4V8HDr9omMeta?.path ?? ":id()",
    meta: _91id_93D4V8HDr9omMeta || {},
    alias: _91id_93D4V8HDr9omMeta?.alias || [],
    redirect: _91id_93D4V8HDr9omMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: faqmHMTB52AP6Meta?.name ?? "help-faq___ur",
    path: faqmHMTB52AP6Meta?.path ?? "/ur/help/faq",
    meta: faqmHMTB52AP6Meta || {},
    alias: faqmHMTB52AP6Meta?.alias || [],
    redirect: faqmHMTB52AP6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93D4V8HDr9omMeta?.name ?? "help-faq-id___ur",
    path: _91id_93D4V8HDr9omMeta?.path ?? ":id()",
    meta: _91id_93D4V8HDr9omMeta || {},
    alias: _91id_93D4V8HDr9omMeta?.alias || [],
    redirect: _91id_93D4V8HDr9omMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/help/faq/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: index6HAiSCcXeQMeta?.name ?? "index___en",
    path: index6HAiSCcXeQMeta?.path ?? "/",
    meta: index6HAiSCcXeQMeta || {},
    alias: index6HAiSCcXeQMeta?.alias || [],
    redirect: index6HAiSCcXeQMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index6HAiSCcXeQMeta?.name ?? "index___ur",
    path: index6HAiSCcXeQMeta?.path ?? "/ur",
    meta: index6HAiSCcXeQMeta || {},
    alias: index6HAiSCcXeQMeta?.alias || [],
    redirect: index6HAiSCcXeQMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/index.vue").then(m => m.default || m)
  },
  {
    name: invite_46client0aUICSiadoMeta?.name ?? undefined,
    path: invite_46client0aUICSiadoMeta?.path ?? "/invite",
    meta: invite_46client0aUICSiadoMeta || {},
    alias: invite_46client0aUICSiadoMeta?.alias || [],
    redirect: invite_46client0aUICSiadoMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite.client.vue").then(m => m.default || m)),
    children: [
  {
    name: income6qnksMHDrbMeta?.name ?? undefined,
    path: income6qnksMHDrbMeta?.path ?? "income",
    meta: income6qnksMHDrbMeta || {},
    alias: income6qnksMHDrbMeta?.alias || [],
    redirect: income6qnksMHDrbMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income.vue").then(m => m.default || m),
    children: [
  {
    name: goalo51JDll1jHMeta?.name ?? "invite-income-goal___en",
    path: goalo51JDll1jHMeta?.path ?? "goal",
    meta: goalo51JDll1jHMeta || {},
    alias: goalo51JDll1jHMeta?.alias || [],
    redirect: goalo51JDll1jHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/goal.vue").then(m => m.default || m)
  },
  {
    name: indexPEnWinOWwOMeta?.name ?? "invite-income___en",
    path: indexPEnWinOWwOMeta?.path ?? "",
    meta: indexPEnWinOWwOMeta || {},
    alias: indexPEnWinOWwOMeta?.alias || [],
    redirect: indexPEnWinOWwOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/index.vue").then(m => m.default || m)
  },
  {
    name: interhO1p6vj6r6Meta?.name ?? "invite-income-inter___en",
    path: interhO1p6vj6r6Meta?.path ?? "inter",
    meta: interhO1p6vj6r6Meta || {},
    alias: interhO1p6vj6r6Meta?.alias || [],
    redirect: interhO1p6vj6r6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/inter.vue").then(m => m.default || m)
  },
  {
    name: rebateKX05c2r0BJMeta?.name ?? "invite-income-rebate___en",
    path: rebateKX05c2r0BJMeta?.path ?? "rebate",
    meta: rebateKX05c2r0BJMeta || {},
    alias: rebateKX05c2r0BJMeta?.alias || [],
    redirect: rebateKX05c2r0BJMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/rebate.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexZXcQLepUjTMeta?.name ?? "invite___en",
    path: indexZXcQLepUjTMeta?.path ?? "",
    meta: indexZXcQLepUjTMeta || {},
    alias: indexZXcQLepUjTMeta?.alias || [],
    redirect: indexZXcQLepUjTMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: rulew60OtyRKzsMeta?.name ?? "invite-rule___en",
    path: rulew60OtyRKzsMeta?.path ?? "rule",
    meta: rulew60OtyRKzsMeta || {},
    alias: rulew60OtyRKzsMeta?.alias || [],
    redirect: rulew60OtyRKzsMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/rule.vue").then(m => m.default || m)
  },
  {
    name: team9vDAX7JurPMeta?.name ?? undefined,
    path: team9vDAX7JurPMeta?.path ?? "team",
    meta: team9vDAX7JurPMeta || {},
    alias: team9vDAX7JurPMeta?.alias || [],
    redirect: team9vDAX7JurPMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team.vue").then(m => m.default || m),
    children: [
  {
    name: bettingGM1sQYJJ4wMeta?.name ?? "invite-team-betting___en",
    path: bettingGM1sQYJJ4wMeta?.path ?? "betting",
    meta: bettingGM1sQYJJ4wMeta || {},
    alias: bettingGM1sQYJJ4wMeta?.alias || [],
    redirect: bettingGM1sQYJJ4wMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/betting.vue").then(m => m.default || m)
  },
  {
    name: index5k9lol4BIfMeta?.name ?? "invite-team___en",
    path: index5k9lol4BIfMeta?.path ?? "",
    meta: index5k9lol4BIfMeta || {},
    alias: index5k9lol4BIfMeta?.alias || [],
    redirect: index5k9lol4BIfMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/index.vue").then(m => m.default || m)
  },
  {
    name: memberHFE9WECBCFMeta?.name ?? "invite-team-member___en",
    path: memberHFE9WECBCFMeta?.path ?? "member",
    meta: memberHFE9WECBCFMeta || {},
    alias: memberHFE9WECBCFMeta?.alias || [],
    redirect: memberHFE9WECBCFMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/member.vue").then(m => m.default || m)
  },
  {
    name: sharelinkXAfnwP9aerMeta?.name ?? "invite-team-sharelink___en",
    path: sharelinkXAfnwP9aerMeta?.path ?? "sharelink",
    meta: sharelinkXAfnwP9aerMeta || {},
    alias: sharelinkXAfnwP9aerMeta?.alias || [],
    redirect: sharelinkXAfnwP9aerMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/sharelink.vue").then(m => m.default || m)
  },
  {
    name: statementTT69unU9IrMeta?.name ?? "invite-team-statement___en",
    path: statementTT69unU9IrMeta?.path ?? "statement",
    meta: statementTT69unU9IrMeta || {},
    alias: statementTT69unU9IrMeta?.alias || [],
    redirect: statementTT69unU9IrMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/statement.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: invite_46client0aUICSiadoMeta?.name ?? undefined,
    path: invite_46client0aUICSiadoMeta?.path ?? "/ur/invite",
    meta: invite_46client0aUICSiadoMeta || {},
    alias: invite_46client0aUICSiadoMeta?.alias || [],
    redirect: invite_46client0aUICSiadoMeta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite.client.vue").then(m => m.default || m)),
    children: [
  {
    name: income6qnksMHDrbMeta?.name ?? undefined,
    path: income6qnksMHDrbMeta?.path ?? "income",
    meta: income6qnksMHDrbMeta || {},
    alias: income6qnksMHDrbMeta?.alias || [],
    redirect: income6qnksMHDrbMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income.vue").then(m => m.default || m),
    children: [
  {
    name: goalo51JDll1jHMeta?.name ?? "invite-income-goal___ur",
    path: goalo51JDll1jHMeta?.path ?? "goal",
    meta: goalo51JDll1jHMeta || {},
    alias: goalo51JDll1jHMeta?.alias || [],
    redirect: goalo51JDll1jHMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/goal.vue").then(m => m.default || m)
  },
  {
    name: indexPEnWinOWwOMeta?.name ?? "invite-income___ur",
    path: indexPEnWinOWwOMeta?.path ?? "",
    meta: indexPEnWinOWwOMeta || {},
    alias: indexPEnWinOWwOMeta?.alias || [],
    redirect: indexPEnWinOWwOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/index.vue").then(m => m.default || m)
  },
  {
    name: interhO1p6vj6r6Meta?.name ?? "invite-income-inter___ur",
    path: interhO1p6vj6r6Meta?.path ?? "inter",
    meta: interhO1p6vj6r6Meta || {},
    alias: interhO1p6vj6r6Meta?.alias || [],
    redirect: interhO1p6vj6r6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/inter.vue").then(m => m.default || m)
  },
  {
    name: rebateKX05c2r0BJMeta?.name ?? "invite-income-rebate___ur",
    path: rebateKX05c2r0BJMeta?.path ?? "rebate",
    meta: rebateKX05c2r0BJMeta || {},
    alias: rebateKX05c2r0BJMeta?.alias || [],
    redirect: rebateKX05c2r0BJMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/income/rebate.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexZXcQLepUjTMeta?.name ?? "invite___ur",
    path: indexZXcQLepUjTMeta?.path ?? "",
    meta: indexZXcQLepUjTMeta || {},
    alias: indexZXcQLepUjTMeta?.alias || [],
    redirect: indexZXcQLepUjTMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/index.vue").then(m => m.default || m)
  },
  {
    name: rulew60OtyRKzsMeta?.name ?? "invite-rule___ur",
    path: rulew60OtyRKzsMeta?.path ?? "rule",
    meta: rulew60OtyRKzsMeta || {},
    alias: rulew60OtyRKzsMeta?.alias || [],
    redirect: rulew60OtyRKzsMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/rule.vue").then(m => m.default || m)
  },
  {
    name: team9vDAX7JurPMeta?.name ?? undefined,
    path: team9vDAX7JurPMeta?.path ?? "team",
    meta: team9vDAX7JurPMeta || {},
    alias: team9vDAX7JurPMeta?.alias || [],
    redirect: team9vDAX7JurPMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team.vue").then(m => m.default || m),
    children: [
  {
    name: bettingGM1sQYJJ4wMeta?.name ?? "invite-team-betting___ur",
    path: bettingGM1sQYJJ4wMeta?.path ?? "betting",
    meta: bettingGM1sQYJJ4wMeta || {},
    alias: bettingGM1sQYJJ4wMeta?.alias || [],
    redirect: bettingGM1sQYJJ4wMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/betting.vue").then(m => m.default || m)
  },
  {
    name: index5k9lol4BIfMeta?.name ?? "invite-team___ur",
    path: index5k9lol4BIfMeta?.path ?? "",
    meta: index5k9lol4BIfMeta || {},
    alias: index5k9lol4BIfMeta?.alias || [],
    redirect: index5k9lol4BIfMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/index.vue").then(m => m.default || m)
  },
  {
    name: memberHFE9WECBCFMeta?.name ?? "invite-team-member___ur",
    path: memberHFE9WECBCFMeta?.path ?? "member",
    meta: memberHFE9WECBCFMeta || {},
    alias: memberHFE9WECBCFMeta?.alias || [],
    redirect: memberHFE9WECBCFMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/member.vue").then(m => m.default || m)
  },
  {
    name: sharelinkXAfnwP9aerMeta?.name ?? "invite-team-sharelink___ur",
    path: sharelinkXAfnwP9aerMeta?.path ?? "sharelink",
    meta: sharelinkXAfnwP9aerMeta || {},
    alias: sharelinkXAfnwP9aerMeta?.alias || [],
    redirect: sharelinkXAfnwP9aerMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/sharelink.vue").then(m => m.default || m)
  },
  {
    name: statementTT69unU9IrMeta?.name ?? "invite-team-statement___ur",
    path: statementTT69unU9IrMeta?.path ?? "statement",
    meta: statementTT69unU9IrMeta || {},
    alias: statementTT69unU9IrMeta?.alias || [],
    redirect: statementTT69unU9IrMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/invite/team/statement.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: _91id_93npDiNXGKEeMeta?.name ?? "provider-id___en",
    path: _91id_93npDiNXGKEeMeta?.path ?? "/provider/:id()",
    meta: _91id_93npDiNXGKEeMeta || {},
    alias: _91id_93npDiNXGKEeMeta?.alias || [],
    redirect: _91id_93npDiNXGKEeMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/provider/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93npDiNXGKEeMeta?.name ?? "provider-id___ur",
    path: _91id_93npDiNXGKEeMeta?.path ?? "/ur/provider/:id()",
    meta: _91id_93npDiNXGKEeMeta || {},
    alias: _91id_93npDiNXGKEeMeta?.alias || [],
    redirect: _91id_93npDiNXGKEeMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/provider/[id].vue").then(m => m.default || m)
  },
  {
    name: index2WjJfJjhjuMeta?.name ?? "providers___en",
    path: index2WjJfJjhjuMeta?.path ?? "/providers",
    meta: index2WjJfJjhjuMeta || {},
    alias: index2WjJfJjhjuMeta?.alias || [],
    redirect: index2WjJfJjhjuMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/providers/index.vue").then(m => m.default || m)
  },
  {
    name: index2WjJfJjhjuMeta?.name ?? "providers___ur",
    path: index2WjJfJjhjuMeta?.path ?? "/ur/providers",
    meta: index2WjJfJjhjuMeta || {},
    alias: index2WjJfJjhjuMeta?.alias || [],
    redirect: index2WjJfJjhjuMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/providers/index.vue").then(m => m.default || m)
  },
  {
    name: _91code_93VtT7p5hq3aMeta?.name ?? "refer-code___en",
    path: _91code_93VtT7p5hq3aMeta?.path ?? "/refer/:code()",
    meta: _91code_93VtT7p5hq3aMeta || {},
    alias: _91code_93VtT7p5hq3aMeta?.alias || [],
    redirect: _91code_93VtT7p5hq3aMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/refer/[code].vue").then(m => m.default || m)
  },
  {
    name: _91code_93VtT7p5hq3aMeta?.name ?? "refer-code___ur",
    path: _91code_93VtT7p5hq3aMeta?.path ?? "/ur/refer/:code()",
    meta: _91code_93VtT7p5hq3aMeta || {},
    alias: _91code_93VtT7p5hq3aMeta?.alias || [],
    redirect: _91code_93VtT7p5hq3aMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/refer/[code].vue").then(m => m.default || m)
  },
  {
    name: indexTXS2NlauKJMeta?.name ?? "search___en",
    path: indexTXS2NlauKJMeta?.path ?? "/search",
    meta: indexTXS2NlauKJMeta || {},
    alias: indexTXS2NlauKJMeta?.alias || [],
    redirect: indexTXS2NlauKJMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexTXS2NlauKJMeta?.name ?? "search___ur",
    path: indexTXS2NlauKJMeta?.path ?? "/ur/search",
    meta: indexTXS2NlauKJMeta || {},
    alias: indexTXS2NlauKJMeta?.alias || [],
    redirect: indexTXS2NlauKJMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: user_46clientgd2VCvxVt3Meta?.name ?? undefined,
    path: user_46clientgd2VCvxVt3Meta?.path ?? "/user",
    meta: user_46clientgd2VCvxVt3Meta || {},
    alias: user_46clientgd2VCvxVt3Meta?.alias || [],
    redirect: user_46clientgd2VCvxVt3Meta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user.client.vue").then(m => m.default || m)),
    children: [
  {
    name: backpacktK5wBjjflnMeta?.name ?? "user-backpack___en",
    path: backpacktK5wBjjflnMeta?.path ?? "backpack",
    meta: backpacktK5wBjjflnMeta || {},
    alias: backpacktK5wBjjflnMeta?.alias || [],
    redirect: backpacktK5wBjjflnMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/backpack.vue").then(m => m.default || m)
  },
  {
    name: collectLO4h0rdLfWMeta?.name ?? "user-collect___en",
    path: collectLO4h0rdLfWMeta?.path ?? "collect",
    meta: collectLO4h0rdLfWMeta || {},
    alias: collectLO4h0rdLfWMeta?.alias || [],
    redirect: collectLO4h0rdLfWMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/collect.vue").then(m => m.default || m)
  },
  {
    name: depositTygoLnEfD1Meta?.name ?? "user-deposit___en",
    path: depositTygoLnEfD1Meta?.path ?? "deposit",
    meta: depositTygoLnEfD1Meta || {},
    alias: depositTygoLnEfD1Meta?.alias || [],
    redirect: depositTygoLnEfD1Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/deposit.vue").then(m => m.default || m)
  },
  {
    name: history9g0z6lcJI5Meta?.name ?? "user-history___en",
    path: history9g0z6lcJI5Meta?.path ?? "history",
    meta: history9g0z6lcJI5Meta || {},
    alias: history9g0z6lcJI5Meta?.alias || [],
    redirect: history9g0z6lcJI5Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/history.vue").then(m => m.default || m)
  },
  {
    name: indexeVLk0lHHI3Meta?.name ?? "user___en",
    path: indexeVLk0lHHI3Meta?.path ?? "",
    meta: indexeVLk0lHHI3Meta || {},
    alias: indexeVLk0lHHI3Meta?.alias || [],
    redirect: indexeVLk0lHHI3Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bJZ0p1ptwSMeta?.name ?? "user-order-deposit-id___en",
    path: _91id_93bJZ0p1ptwSMeta?.path ?? "order/deposit/:id()",
    meta: _91id_93bJZ0p1ptwSMeta || {},
    alias: _91id_93bJZ0p1ptwSMeta?.alias || [],
    redirect: _91id_93bJZ0p1ptwSMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/deposit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nM8X654LTZMeta?.name ?? "user-order-withdraw-id___en",
    path: _91id_93nM8X654LTZMeta?.path ?? "order/withdraw/:id()",
    meta: _91id_93nM8X654LTZMeta || {},
    alias: _91id_93nM8X654LTZMeta?.alias || [],
    redirect: _91id_93nM8X654LTZMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/withdraw/[id].vue").then(m => m.default || m)
  },
  {
    name: settingVHjMnHpYxQMeta?.name ?? undefined,
    path: settingVHjMnHpYxQMeta?.path ?? "setting",
    meta: settingVHjMnHpYxQMeta || {},
    alias: settingVHjMnHpYxQMeta?.alias || [],
    redirect: settingVHjMnHpYxQMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93jKXiCD5S5SMeta?.name ?? "user-setting-agreement-id___en",
    path: _91id_93jKXiCD5S5SMeta?.path ?? "agreement/:id()",
    meta: _91id_93jKXiCD5S5SMeta || {},
    alias: _91id_93jKXiCD5S5SMeta?.alias || [],
    redirect: _91id_93jKXiCD5S5SMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/agreement/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFfKLr986CdMeta?.name ?? "user-setting-changepwd___en",
    path: indexFfKLr986CdMeta?.path ?? "changepwd",
    meta: indexFfKLr986CdMeta || {},
    alias: indexFfKLr986CdMeta?.alias || [],
    redirect: indexFfKLr986CdMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/changepwd/index.vue").then(m => m.default || m)
  },
  {
    name: index9NENkI8f6KMeta?.name ?? "user-setting-feedback___en",
    path: index9NENkI8f6KMeta?.path ?? "feedback",
    meta: index9NENkI8f6KMeta || {},
    alias: index9NENkI8f6KMeta?.alias || [],
    redirect: index9NENkI8f6KMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/feedback/index.vue").then(m => m.default || m)
  },
  {
    name: indexkW9hQGPFCBMeta?.name ?? "user-setting___en",
    path: indexkW9hQGPFCBMeta?.path ?? "",
    meta: indexkW9hQGPFCBMeta || {},
    alias: indexkW9hQGPFCBMeta?.alias || [],
    redirect: indexkW9hQGPFCBMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexIiFkD60mVOMeta?.name ?? "user-setting-profile___en",
    path: indexIiFkD60mVOMeta?.path ?? "profile",
    meta: indexIiFkD60mVOMeta || {},
    alias: indexIiFkD60mVOMeta?.alias || [],
    redirect: indexIiFkD60mVOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactioniDBhXjNkhKMeta?.name ?? "user-transaction___en",
    path: transactioniDBhXjNkhKMeta?.path ?? "transaction",
    meta: transactioniDBhXjNkhKMeta || {},
    alias: transactioniDBhXjNkhKMeta?.alias || [],
    redirect: transactioniDBhXjNkhKMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TvfjTOMm0tMeta?.name ?? "user-vipclub-agreement-id___en",
    path: _91id_93TvfjTOMm0tMeta?.path ?? "vipclub/agreement/:id()",
    meta: _91id_93TvfjTOMm0tMeta || {},
    alias: _91id_93TvfjTOMm0tMeta?.alias || [],
    redirect: _91id_93TvfjTOMm0tMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/agreement/[id].vue").then(m => m.default || m)
  },
  {
    name: index35PkU6XSY5Meta?.name ?? "user-vipclub___en",
    path: index35PkU6XSY5Meta?.path ?? "vipclub",
    meta: index35PkU6XSY5Meta || {},
    alias: index35PkU6XSY5Meta?.alias || [],
    redirect: index35PkU6XSY5Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/index.vue").then(m => m.default || m)
  },
  {
    name: withdraw8UAInu9cC6Meta?.name ?? "user-withdraw___en",
    path: withdraw8UAInu9cC6Meta?.path ?? "withdraw",
    meta: withdraw8UAInu9cC6Meta || {},
    alias: withdraw8UAInu9cC6Meta?.alias || [],
    redirect: withdraw8UAInu9cC6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/withdraw.vue").then(m => m.default || m)
  }
]
  },
  {
    name: user_46clientgd2VCvxVt3Meta?.name ?? undefined,
    path: user_46clientgd2VCvxVt3Meta?.path ?? "/ur/user",
    meta: user_46clientgd2VCvxVt3Meta || {},
    alias: user_46clientgd2VCvxVt3Meta?.alias || [],
    redirect: user_46clientgd2VCvxVt3Meta?.redirect,
    component: () => createClientPage(() => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user.client.vue").then(m => m.default || m)),
    children: [
  {
    name: backpacktK5wBjjflnMeta?.name ?? "user-backpack___ur",
    path: backpacktK5wBjjflnMeta?.path ?? "backpack",
    meta: backpacktK5wBjjflnMeta || {},
    alias: backpacktK5wBjjflnMeta?.alias || [],
    redirect: backpacktK5wBjjflnMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/backpack.vue").then(m => m.default || m)
  },
  {
    name: collectLO4h0rdLfWMeta?.name ?? "user-collect___ur",
    path: collectLO4h0rdLfWMeta?.path ?? "collect",
    meta: collectLO4h0rdLfWMeta || {},
    alias: collectLO4h0rdLfWMeta?.alias || [],
    redirect: collectLO4h0rdLfWMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/collect.vue").then(m => m.default || m)
  },
  {
    name: depositTygoLnEfD1Meta?.name ?? "user-deposit___ur",
    path: depositTygoLnEfD1Meta?.path ?? "deposit",
    meta: depositTygoLnEfD1Meta || {},
    alias: depositTygoLnEfD1Meta?.alias || [],
    redirect: depositTygoLnEfD1Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/deposit.vue").then(m => m.default || m)
  },
  {
    name: history9g0z6lcJI5Meta?.name ?? "user-history___ur",
    path: history9g0z6lcJI5Meta?.path ?? "history",
    meta: history9g0z6lcJI5Meta || {},
    alias: history9g0z6lcJI5Meta?.alias || [],
    redirect: history9g0z6lcJI5Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/history.vue").then(m => m.default || m)
  },
  {
    name: indexeVLk0lHHI3Meta?.name ?? "user___ur",
    path: indexeVLk0lHHI3Meta?.path ?? "",
    meta: indexeVLk0lHHI3Meta || {},
    alias: indexeVLk0lHHI3Meta?.alias || [],
    redirect: indexeVLk0lHHI3Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bJZ0p1ptwSMeta?.name ?? "user-order-deposit-id___ur",
    path: _91id_93bJZ0p1ptwSMeta?.path ?? "order/deposit/:id()",
    meta: _91id_93bJZ0p1ptwSMeta || {},
    alias: _91id_93bJZ0p1ptwSMeta?.alias || [],
    redirect: _91id_93bJZ0p1ptwSMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/deposit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nM8X654LTZMeta?.name ?? "user-order-withdraw-id___ur",
    path: _91id_93nM8X654LTZMeta?.path ?? "order/withdraw/:id()",
    meta: _91id_93nM8X654LTZMeta || {},
    alias: _91id_93nM8X654LTZMeta?.alias || [],
    redirect: _91id_93nM8X654LTZMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/order/withdraw/[id].vue").then(m => m.default || m)
  },
  {
    name: settingVHjMnHpYxQMeta?.name ?? undefined,
    path: settingVHjMnHpYxQMeta?.path ?? "setting",
    meta: settingVHjMnHpYxQMeta || {},
    alias: settingVHjMnHpYxQMeta?.alias || [],
    redirect: settingVHjMnHpYxQMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93jKXiCD5S5SMeta?.name ?? "user-setting-agreement-id___ur",
    path: _91id_93jKXiCD5S5SMeta?.path ?? "agreement/:id()",
    meta: _91id_93jKXiCD5S5SMeta || {},
    alias: _91id_93jKXiCD5S5SMeta?.alias || [],
    redirect: _91id_93jKXiCD5S5SMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/agreement/[id].vue").then(m => m.default || m)
  },
  {
    name: indexFfKLr986CdMeta?.name ?? "user-setting-changepwd___ur",
    path: indexFfKLr986CdMeta?.path ?? "changepwd",
    meta: indexFfKLr986CdMeta || {},
    alias: indexFfKLr986CdMeta?.alias || [],
    redirect: indexFfKLr986CdMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/changepwd/index.vue").then(m => m.default || m)
  },
  {
    name: index9NENkI8f6KMeta?.name ?? "user-setting-feedback___ur",
    path: index9NENkI8f6KMeta?.path ?? "feedback",
    meta: index9NENkI8f6KMeta || {},
    alias: index9NENkI8f6KMeta?.alias || [],
    redirect: index9NENkI8f6KMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/feedback/index.vue").then(m => m.default || m)
  },
  {
    name: indexkW9hQGPFCBMeta?.name ?? "user-setting___ur",
    path: indexkW9hQGPFCBMeta?.path ?? "",
    meta: indexkW9hQGPFCBMeta || {},
    alias: indexkW9hQGPFCBMeta?.alias || [],
    redirect: indexkW9hQGPFCBMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/index.vue").then(m => m.default || m)
  },
  {
    name: indexIiFkD60mVOMeta?.name ?? "user-setting-profile___ur",
    path: indexIiFkD60mVOMeta?.path ?? "profile",
    meta: indexIiFkD60mVOMeta || {},
    alias: indexIiFkD60mVOMeta?.alias || [],
    redirect: indexIiFkD60mVOMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/setting/profile/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: transactioniDBhXjNkhKMeta?.name ?? "user-transaction___ur",
    path: transactioniDBhXjNkhKMeta?.path ?? "transaction",
    meta: transactioniDBhXjNkhKMeta || {},
    alias: transactioniDBhXjNkhKMeta?.alias || [],
    redirect: transactioniDBhXjNkhKMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/transaction.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TvfjTOMm0tMeta?.name ?? "user-vipclub-agreement-id___ur",
    path: _91id_93TvfjTOMm0tMeta?.path ?? "vipclub/agreement/:id()",
    meta: _91id_93TvfjTOMm0tMeta || {},
    alias: _91id_93TvfjTOMm0tMeta?.alias || [],
    redirect: _91id_93TvfjTOMm0tMeta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/agreement/[id].vue").then(m => m.default || m)
  },
  {
    name: index35PkU6XSY5Meta?.name ?? "user-vipclub___ur",
    path: index35PkU6XSY5Meta?.path ?? "vipclub",
    meta: index35PkU6XSY5Meta || {},
    alias: index35PkU6XSY5Meta?.alias || [],
    redirect: index35PkU6XSY5Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/vipclub/index.vue").then(m => m.default || m)
  },
  {
    name: withdraw8UAInu9cC6Meta?.name ?? "user-withdraw___ur",
    path: withdraw8UAInu9cC6Meta?.path ?? "withdraw",
    meta: withdraw8UAInu9cC6Meta || {},
    alias: withdraw8UAInu9cC6Meta?.alias || [],
    redirect: withdraw8UAInu9cC6Meta?.redirect,
    component: () => import("/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/pages/user/withdraw.vue").then(m => m.default || m)
  }
]
  },
  {
    name: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta?.name ?? undefined,
    path: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta?.path ?? "/sitemap.xml",
    meta: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta || {},
    alias: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta?.alias || [],
    redirect: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57FcMeta?.redirect,
    component: nuxt_643_4611_461__64parcel_43watcher_642_464_461__64types_43node_6422_4610_461_encoding_640_461_4613_eslint_648_46578qtKNV57Fc
  }
]