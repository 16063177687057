import type { ComputedRef, Ref } from 'vue'

export const useUserStore = defineStore('userStore', () => {
  // user info
  const userInfo: Ref<Iuser_basic_info> = ref({})
  const initUserInfo = async () => {
    const user = await request(userInfoDetail({}))
    userInfo.value = user?.userInfo || {}
    if (!userInfo.value?.guest) {
      setUserRegChn(userInfo.value?.registerChannel)
    } else {
      removeUserRegChn()
    }
  }

  // user auth
  const isLogin = computed(() => !!userInfo.value && Object.keys(userInfo.value).length > 0 && !userInfo.value?.guest)
  const _userCurrencyId: Ref<number> = ref(0)
  const userCurrencyId = computed(() => _userCurrencyId.value)
  const token = ref()
  const { actSysList } = storeToRefs(useActConfStore())
  const { currencyConfList } = storeToRefs(useGlobalStore())
  
  const refreshUserToken = async () => {
    const response = await request(userInfoEnhanceToken({}))
    token.value = response?.token || ''
    userInfo.value = response?.basicInfo || {}
    userWalletList.value = response?.walletList || []
    actSysList.value = response?.activityList || []
    if (!userInfo.value?.guest) {
      setUserRegChn(userInfo.value?.registerChannel)
    } else {
      removeUserRegChn()
    }
    saveUserToken(response?.token)
    console.debug('user authed', response.token, isLogin.value)
    // }
    initUserCurrency()
  }

  const guestLogin = async () => {
    removeUserRegChn()
    const response = await request(
      userLoginGuest({ deviceNo: getLocalBase64(StorageKey.DEVICE_ID) || '-1', simulatorSign: false })
    )
    saveUserToken(response?.token)
    userInfo.value = response?.basicInfo || {}
    actSysList.value = response?.activityList || []

    initUserCurrency()
  }

  function saveUserToken(token: string, isSignin: boolean = false) {
    setLocalBase64(StorageKey.TOKEN, token)
    isSignin && setLocal(StorageKey.LAST_SIGNIN, Date.now())
  }

  const getUserToken = () => getLocalBase64(StorageKey.TOKEN)
  const clearUserData = () => {
    userInfo.value = {}
    removeUserRegChn()
    removeLocal(StorageKey.LAST_SIGNIN)
    saveUserToken('')
  }

  const userLogout = async () => {
    await request(userInfoLogout({}))
    clearUserData()
    jumRootUrl(true)
  }

  // user layout
  // const userLayoutInfo: Ref<Iapp_layout_info> = ref(getLocalDESJson('layout') || {})
  // function saveUserLayout(appLayoutInfo: Iapp_layout_info) {
  //   setLocalDESJson('layout', appLayoutInfo)
  //   userLayoutInfo.value = appLayoutInfo
  // }
  // const userNavList: ComputedRef<Inavbar_info[]> = computed(() => {
  //   if (!userLayoutInfo.value.navbarList) return []
  //   return userLayoutInfo.value.navbarList
  // })

  // const availableHeaderNavTypes: number[] = [navbar_type_const.navbar_game, navbar_type_const.navbar_activity]
  // const userHeaderNavList: ComputedRef<Inavbar_info[]> = computed(() => {
  //   return userNavList.value.filter((t) => availableHeaderNavTypes.indexOf(t?.type) > -1)
  // })

  // const availableBottomNavTypes: number[] = [
  //   navbar_type_const.navbar_game,
  //   navbar_type_const.navbar_activity,
  //   navbar_type_const.navbar_mine,
  //   navbar_type_const.navbar_invitation,
  // ]
  // const userBottomNavList: ComputedRef<Inavbar_info[]> = computed(() => {
  //   return userNavList.value.filter((t) => availableBottomNavTypes.indexOf(t?.type) > -1)
  // })

  // user wallet

  const userWalletList: Ref<wallet_list_res.Iwallet_info[]> = ref([])
  const userBalanceMap: ComputedRef<Record<number, string>> = computed(() => {
    return userWalletList.value.reduce(
      (prev, item) => Object.assign(prev, { [item.currencyId]: item.currencyBalance || '0' }),
      {} as Record<number, string>
    )
  })

  const userCurrencyCode: Ref<string> = ref('0')
  const userCurrencyIcon: Ref<string> = ref('0')
  const userCurrencySymbol: Ref<string> = ref('0')
  const userCurrencyBalance: Ref<string> = ref('0')

  async function initUserCurrency() {
    if (isLogin.value) {
      _userCurrencyId.value = Number(getLocal(StorageKey.CURRENCY_ID + '_' + userInfo.value?.userId))
    }

    if (
      !_userCurrencyId.value ||
      currencyConfList.value?.findIndex((item) => item.currencyId === _userCurrencyId.value) === -1
    ) {
      setUserCurrency(currencyConfList.value?.[0]?.currencyId)
    }
  }

  // async function initCurrencyConf() {
  //   // const currencyList = await request(walletCurrencyList({}))
  //   // currencyConfList.value = currencyList?.list || []

  //   if (isLogin.value) {
  //     _userCurrencyId.value = Number(getLocal(StorageKey.CURRENCY_ID + '_' + userInfo.value?.userId))
  //   }

  //   if (
  //     !_userCurrencyId.value ||
  //     currencyConfList.value.findIndex((item) => item.currencyId === _userCurrencyId.value) === -1
  //   ) {
  //     setUserCurrency(currencyConfList.value[0]?.currencyId)
  //   }
  // }

  const refreshUserCurrency = async () => {
    const wallets = await request(walletList({}))
    userWalletList.value = wallets?.list || []
  }

  const updateUserBalance = (currencyId: number, val: any) => {
    for (const wallet of userWalletList.value) {
      if (wallet.currencyId === currencyId) {
        wallet.currencyBalance = val
        return
      }
    }
  }

  const setUserCurrency = async (id: number) => {
    _userCurrencyId.value = id
    isLogin.value && setLocal(StorageKey.CURRENCY_ID + '_' + userInfo.value?.userId, _userCurrencyId.value)
  }
  watchEffect(() => {
    if (
      !_userCurrencyId.value ||
      currencyConfList.value?.findIndex((item) => item.currencyId === _userCurrencyId.value) === -1
    ) {
      setUserCurrency(currencyConfList.value?.[0]?.currencyId)
    }
  })
  watchEffect(() => {
    const currencyInfo = currencyConfList.value?.find((item) => item.currencyId === _userCurrencyId.value)
    userCurrencyIcon.value = currencyInfo?.currencyIcon || ''
    userCurrencyCode.value = currencyInfo?.currencyCode || ''
    userCurrencySymbol.value = currencyInfo?.currencySymbol || ''

    const walletInfo = userWalletList.value.find((item) => item.currencyId === _userCurrencyId.value)
    userCurrencyBalance.value = walletInfo?.currencyBalance || '0'
  })

  const hasNewProp: Ref<boolean> = ref(!!getLocal('new_prop'))
  const setHasNewProp = (has: boolean) => {
    if (has) {
      setLocal('new_prop', true)
    } else {
      removeLocal('new_prop')
    }
    hasNewProp.value = has
  }

  const userVipInfo: Ref<Iuser_info_level_res | undefined> = ref()
  const initVipLevel = async () => {
    userVipInfo.value = await request(userInfoLevel({}))
  }

  // const userPulicInfo: Ref<Iquery_public_info_res | null> = ref(null)
  // async function initUserPublicDetail() {
  //   userPulicInfo.value = await request(
  //     userInfoPublicDetail({
  //       userId: Number(userInfo.value?.userId),
  //     })
  //   )
  // }

  return {
    isLogin,
    userInfo,
    userBalanceMap,
    userCurrencyId,
    userCurrencyCode,
    userCurrencySymbol,
    userCurrencyIcon,
    userCurrencyBalance,
    initUserInfo,
    saveUserToken,
    getUserToken,
    clearUserData,
    hasNewProp,
    setHasNewProp,
    setUserCurrency,
    refreshUserToken,
    refreshUserCurrency,
    updateUserBalance,
    userVipInfo,
    initVipLevel,
    guestLogin,
    userLogout,
  }
})
