interface LatestBetsInfo extends game_rank_bet_latest_user_res.Iuser_info {
  key: any
}

// casino 标签
export const useCasinoGameTags = defineStore('casinoGameTagStore', () => {
  // 首页数据
  const gameTags: Ref<game_tag_list_res.Itag_info[]> = ref([])
  const gameTagMap: ComputedRef<Record<string, game_tag_list_res.Itag_info>> = computed(() => {
    return gameTags.value.reduce(
      (prev, curr) => {
        prev[curr.id] = curr
        return prev
      },
      {} as Record<string, game_tag_list_res.Itag_info>
    )
  })
  async function initGameTags() {
    const response = await request(gameTagList({}), {}, true)
    gameTags.value = response?.tags || []
  }

  return { gameTags, gameTagMap, initGameTags }
})
// casino 首页配置
export const useCasinoIndexConfStore = defineStore('casinoIndexConfStore', () => {
  // 首页数据
  const indexConf = ref([])
  const { $i18n } = useNuxtApp()
  async function initIndexConf() {
    try {
      const response = await request(gameHomeMerge({ pageIndex: 1, pageSize: process.server ? 6 : 24 }), {}, true)
      const { tagList, raceBiggestGameList, gamePlatformList } = response || {}
      const config = []
      config.push({
        id: '100241',
        name: $i18n.t('recentlyText'),
        icon: ['games/recently', 'games/recently-white'],
        children: [{ type: 'history_type', expand: true, more: true }],
      })
      config.push({
        id: '100242',
        name: $i18n.t('lobbyText'),
        icon: ['games/spinner', 'games/spinner-white'],
        children: [
          {
            type: 'big_winner',
            title: $i18n.t('recent-big-wins'),
            more: false,
            data: raceBiggestGameList?.list || [],
          },
          ...(tagList?.map((item) => ({
            type: 'category_tag',
            title: item.name || '',
            more: true,
            param: { tagId: item.id },
            data: item?.games || [],
            total: item?.itemCount || 0,
          })) || []),
          {
            type: 'provider',
            title: $i18n.t('gameProvidersText'),
            more: true,
            data: gamePlatformList?.platforms || [],
            total: gamePlatformList?.platforms?.length || 0,
          },
          { type: 'latest_bet_race', title: $i18n.t('latest-bet-and-race') },
        ],
      })
      config.push({
        id: '100243',
        name: $i18n.t('slotsText'),
        icon: ['games/slots', 'games/slots-white'],
        children: [
          {
            type: 'category_type',
            title: $i18n.t('slotsText'),
            instructionLink: '/games/instr/4',
            more: true,
            expand: true,
            param: { type: 4 },
          },
        ],
      })
      config.push({
        id: '100244',
        name: $i18n.t('liveCasinoText'),
        icon: ['games/glive', 'games/glive-white'],
        children: [
          {
            type: 'category_type',
            title: $i18n.t('liveCasinoText'),
            instructionLink: '/games/instr/2',
            more: true,
            expand: true,
            param: { type: 2 },
          },
        ],
      })
      config.push({
        id: '100245',
        name: $i18n.t('fishing-game-type'),
        icon: ['games/fishing', 'games/fishing-white'],
        children: [
          {
            type: 'category_type',
            title: $i18n.t('fishing-game-type'),
            instructionLink: `/games/instr/${GameType_const.GameTypeFishing}`,
            more: true,
            expand: true,
            param: { type: GameType_const.GameTypeFishing },
          },
        ],
      })
      config.push({
        id: '100246',
        name: $i18n.t('title.poker'),
        icon: ['games/poker', 'games/poker-white'],
        children: [
          {
            type: 'category_type',
            instructionLink: `/games/instr/${GameType_const.GameTypeBoardGame}`,
            more: true,
            expand: true,
            param: { type: GameType_const.GameTypeBoardGame },
          },
        ],
      })
      config.push({
        id: '100247',
        name: $i18n.t('sportsText'),
        icon: ['games/sports', 'games/sports-white'],
        children: [
          {
            type: 'category_type',
            title: $i18n.t('sportsText'),
            instructionLink: '/games/instr/1',
            more: true,
            expand: true,
            param: { type: 1 },
          },
        ],
      })

      indexConf.value = config
      return indexConf.value
    } catch (e) {
      Log.error(e)
    }
  }
  function lazyInitGames(idx: number) {
    if (!indexConf.value?.[idx]?.children) return
    return batchRequestGameOprConf(indexConf.value[idx].children)
  }
  return {
    indexConf,
    initIndexConf,
    lazyInitGames,
  }
})

// casino 首页配置v2
export const useCasinoIndexConfV2Store = defineStore('casinoIndexConfV2Store', () => {
  // 首页数据
  const indexConfV2: Ref<Igame_home_merge_v4_resp> = ref({})

  const indexData = computed(() => {
    const { GAME_TYPE_KEY } = useGameType()
    const {
      bannerList,
      streamerList,
      platformList,
      hotList,
      slotsList,
      liveCasinoList,
      fishingList,
      pokerList,
      sportsList,
    } = indexConfV2.value || {}
    return {
      banner: bannerList || [],
      stream: streamerList,
      platforms: platformList || [],
      hotGames: hotList,
      catGames: {
        [GAME_TYPE_KEY.slots]: slotsList || [],
        [GAME_TYPE_KEY.live]: liveCasinoList || [],
        [GAME_TYPE_KEY.fishing]: fishingList || [],
        [GAME_TYPE_KEY.poker]: pokerList || [],
        [GAME_TYPE_KEY.sports]: sportsList || [],
      },
    }
  })

  async function initIndexConfV2() {
    try {
      const response = await request(gameNewHomeMerge({}), {}, true)
      indexConfV2.value = response || {}
    } catch (e) {
      Log.error(e)
      indexConfV2.value = {}
    }
    return indexConfV2.value
  }
  return {
    indexData,
    initIndexConfV2,
  }
})

// casino 游戏页运营位配置
export const useGameOprConfStore = defineStore('gameOprConfStore', () => {
  // 首页数据
  let gameOprConf: Ref<GameOperationConfChild[]> = ref([])
  const { $i18n } = useNuxtApp()
  async function initGameOprConf() {
    const config: GameOperationConfChild[] = [
      { type: 'category_tag', title: $i18n.t('recommended-games'), more: false, remote: true },
      { type: 'provider', title: $i18n.t('gameProvidersText'), remote: true, more: true },
    ]
    try {
      await batchRequestGameOprConf(config)
      gameOprConf.value = [...config]
      return gameOprConf.value
    } catch (e) {
      Log.error(e)
    } finally {
      return [...config]
    }
  }
  return {
    gameOprConf,
    initGameOprConf,
  }
})

// 厂商列表
export const useProviderStore = defineStore('providerStore', () => {
  const providers: Ref<game_platform_list_res.Iplatform_info[]> = ref([])
  const providerMap: ComputedRef<Record<string, game_platform_list_res.Iplatform_info>> = computed(() => {
    return providers.value.reduce(
      (prev, curr) => {
        prev[curr.platformKey] = curr
        return prev
      },
      {} as Record<string, game_platform_list_res.Iplatform_info>
    )
  })
  async function initProviders() {
    const response = await request(gamePlatformList({}))
    providers.value = response?.platforms || []
  }
  return { providers, providerMap, initProviders }
})

export const useRcmdGames = defineStore('rcmdGamesStore', () => {
  const rcmdGames: Ref<Igame_info[]> = ref([])
  async function requestRcmdGames() {
    const rcmdParams = { pageIndex: 1, pageSize: 24, gameType: GameType_const.GameTypeAll }
    const response = await request(gameListRecommend(rcmdParams), {}, true, 5 * 60 * 1000)
    rcmdGames.value = response?.games || []
  }
  return {
    rcmdGames,
    requestRcmdGames,
  }
})

export const useCasinoStore = defineStore('casinoStore', () => {
  // 推荐游戏
  const rcmdGames = useLazyData([], async () => {
    return (await request(gameListRecommend({ pageIndex: 1, pageSize: 24 })))?.games || []
  })

  // prettier-ignore
  // 最近投注
  const latestBets: Ref<LatestBetsInfo[]> = ref([])
  const _latestBetsStack: Ref<LatestBetsInfo[]> = ref([])
  const genId = (() => {
    let idx = 0
    return () => (idx = (idx + 1) % 1000)
  })()

  // 排行
  const biggestData: Ref<game_rank_race_biggest_user_res.Iuser_info[]> = ref([])
  async function initGameRankRaceBiggestUser() {
    // if (biggestData.value?.length) return
    // const response = await request(gameRankRaceBiggestUser({}))
    const response = await request(gameRankMerge({}), {}, true)
    biggestData.value = response?.raceBiggestUserList?.list || []
    latestBets.value = response?.betLatestUserList?.list?.map((item) => ({ ...item, key: genId() })) || []
    while (response?.betLatestUserList?.list?.length && latestBets.value.length <= 10) {
      latestBets.value.push(...response?.betLatestUserList?.list.map((item) => ({ ...item, key: genId() })))
    }
  }

  function flushLatestBets() {
    if (process.server) return
    nextTick(() => {
      while (latestBets.value.length > 10) {
        const bet = latestBets.value.pop()
        if (_latestBetsStack.value.length < 10) {
          _latestBetsStack.value.unshift({ ...bet, key: genId() })
        }
      }
    })
  }

  // async function subscribeLatestBets(subscription: boolean) {
  //   if (process.server) return
  //   request(gameRankBetLatestUser({ subscription }))
  //   // const response = await
  //   // if (subscription) {
  //   //   latestBets.value = response.list?.map((item) => ({ ...item, key: genId() })) || []
  //   //   while (response.list?.length && latestBets.value.length <= 10) {
  //   //     latestBets.value.push(...response.list.map((item) => ({ ...item, key: genId() })))
  //   //   }
  //   //   flushLatestBets()
  //   // }
  // }

  async function shiftLatestBets(bets: game_rank_bet_latest_user_res.Iuser_info[] = []) {
    if (_latestBetsStack.value.length <= 50)
      _latestBetsStack.value.push(...bets.map((item) => ({ ...item, key: genId() })))
    flushLatestBets()
  }

  function fetchLatestBets() {
    const cnt = Math.min(Math.ceil(Math.random() * 5), _latestBetsStack.value.length)
    for (let i = 0; i < cnt; i++) {
      latestBets.value.unshift(_latestBetsStack.value.pop()!)
    }
    flushLatestBets()
  }

  return {
    rcmdGames,
    latestBets,
    biggestData,
    // subscribeLatestBets,
    initGameRankRaceBiggestUser,
    shiftLatestBets,
    fetchLatestBets,
  }
})
