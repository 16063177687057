export default defineNuxtPlugin({
  name: 'AUTH',
  async setup(nuxtApp) {
    if (process.server) return

    function checkAuth(e: Event) {
      if ((e.currentTarget as any).binding.value !== false) {
        const { isLogin } = useUserStore()
        if (!isLogin) {
          e.stopPropagation()
          e.preventDefault()
          openModalRoute({ modal: 'ath', tab: 'signin' })
          return false
        }
      }
      return true
    }

    nuxtApp.vueApp.directive('auth', {
      // 在绑定元素的父组件
      // 及他自己的所有子节点都挂载完成后调用
      mounted(el: any, binding, _vnode, _prevVnode) {
        el.binding = binding
        el.addEventListener('click', checkAuth, true)
      },

      // 绑定元素的父组件卸载后调用
      unmounted(el, binding, _vnode, _prevVnode) {
        el.removeEventListener('click', checkAuth, true)
      },
    })
  },
})
