type ValueOf<T> = T[keyof T]
export const ErrorTips: Record<ValueOf<typeof ErrorCode_const>, string> = {
  [ErrorCode_const.NotProxyUser]: 'ssNotProxyUser',
  [ErrorCode_const.NO]: 'ssOk',
  [ErrorCode_const.UnKnow]: 'ssUnknownErrorTips',
  [ErrorCode_const.UserNotStreamer]: 'ssNotAnchorTips',
  [ErrorCode_const.UserAlreadyStreamer]: 'ssAlreadyAnchorTips',
  [ErrorCode_const.UserAccountOrPassword]: 'ssAccountOrPassWordErrorTips',
  [ErrorCode_const.InternalServer]: 'ssServiceErrorTips',
  [ErrorCode_const.RequestData]: 'ssParameterErrorTips',
  [ErrorCode_const.InterfaceInvalid]: 'ssInvalidInterfaceTips',
  [ErrorCode_const.UserUnLogin]: 'ssNotLogTips',
  [ErrorCode_const.UserNotExist]: 'ssNoteExitTips',
  [ErrorCode_const.UserAlreadyExist]: 'ssUserAlreadyExistTips',
  [ErrorCode_const.UserTokenInvalid]: 'ssInvalidTokenTips',
  [ErrorCode_const.UserTokenExpire]: 'ssTokenExpiredTips',
  [ErrorCode_const.UserPhoneAlreadyRegister]: 'ssMobileRegisteredTips',
  [ErrorCode_const.UserKickedCdNotExpire]: 'ssKickedExpired',
  [ErrorCode_const.UserCoinNotEnough]: 'ssEditRunTips',
  [ErrorCode_const.BackpackGiftNotEnough]: 'ssInsufficientQuantityTips',
  [ErrorCode_const.InSufficientWithdrawRule]: 'ssInSufficientWithdrawRule',
  [ErrorCode_const.OriginPassword]: 'ssOriginalPasswordTips',
  [ErrorCode_const.BankCardInvalid]: 'ssBankCardInvalid',
  [ErrorCode_const.RechargeFail]: 'ssRechargeFail',
  [ErrorCode_const.CountryCodeInvalid]: 'ssCountryCodeInvalid',
  [ErrorCode_const.RechargeChannelNotAvailable]: 'ssRechargeChannelNotAvailable',
  [ErrorCode_const.InviteCodeOwnerSelf]: 'ssInviteCodeOwnerSelf',
  [ErrorCode_const.RechargeOpFrequently]: 'ssRechargeOpFrequently',
  [ErrorCode_const.AlreadyBindInviteCode]: 'ssAlreadyBindInviteCode',
  [ErrorCode_const.InviteCodeAlreadyExist]: 'ssInviteCodeAlreadyExist',
  [ErrorCode_const.InviteCodeNotExist]: 'ssInviteCodeNotExist',
  [ErrorCode_const.UserNotPermission]: 'ssUserNotPermission',
  [ErrorCode_const.UserAlreadyAdmin]: 'ssUserAlreadyAdmin',
  [ErrorCode_const.AccountForbid]: 'ssAccountForbid',
  [ErrorCode_const.LiveAdminNotBeGuest]: 'ssLiveAdminNotBeGuest',
  [ErrorCode_const.BankCardAlreadyBind]: 'ssBankCardAlreadyBindErrorTips',
  [ErrorCode_const.ThirdTokenExpired]: '',
  [ErrorCode_const.LiveHasEnded]: '',
  [ErrorCode_const.ActivityHasBeenClosed]: 'ssActivityHasBeenClosed',
  [ErrorCode_const.WithdrawInSufficientMinCoin]: 'ssWithdrawInSufficientMinCoinTips',
  [ErrorCode_const.WithdrawSurpassMaxCoin]: 'ssWithdrawSurpassMaxCoinTips',
  [ErrorCode_const.WithdrawSurpassDailyNum]: 'ssWithdrawSurpassDailyNumTips',
  [ErrorCode_const.WithdrawSurpassDailyMaxCoin]: 'ssWithdrawSurpassDailyMaxCoinTips',
  [ErrorCode_const.ChannelForbid]: 'ssChannelForbid',
  [ErrorCode_const.LiveStreamerForbid]: 'ssLiveStreamerForbid',
  [ErrorCode_const.UserCurrencyNotEnough]: 'ssUserCurrencyNotEnough',
  [ErrorCode_const.ChannelNotExist]: 'ssChannelNotExist',
  [ErrorCode_const.PhoneNotBindPhone]: 'ssPhoneNotBindPhone',
  [ErrorCode_const.UserNotBindPhone]: 'ssUserNotBindPhone',
  [ErrorCode_const.TaskStatusNotFinish]: 'ssTaskStatusNotFinish',
  [ErrorCode_const.TaskRewardHasGave]: 'ssTaskRewardHasGave',
  [ErrorCode_const.DeviceGetRewardTooMuch]: 'ssDeviceGetRewardTooMuch',
  [ErrorCode_const.RewardNumNotEnough]: 'ssRewardNumNotEnough',
  [ErrorCode_const.ServerStop]: 'ssServerStop',
  [ErrorCode_const.AccountFreeze]: 'ssAccountFreeze',
  [ErrorCode_const.IpForbid]: 'ssIpForbid',
  [ErrorCode_const.DeviceForbid]: 'ssDeviceForbid',
  [ErrorCode_const.UserModifyPhoneForbid]: 'ssUserModifyPhoneForbid',
  [ErrorCode_const.EmulatorForbid]: 'ssEmulatorForbid',
  [ErrorCode_const.RechargeCouponUseFail]: 'ssRechargeCouponUseFail',
  [ErrorCode_const.DeviceDeniedLogin]: 'ssDeviceDeniedLogin',
  [ErrorCode_const.AccountDeniedLogin]: 'ssAccountDeniedLogin',
  [ErrorCode_const.WithdrawOkayAmountNotEnough]: 'ssWithdrawOkayAmountNotEnough',
  [ErrorCode_const.AccountFormatNotSupport]: 'ssAccountFormatNotSupport',
  [ErrorCode_const.PasswordFormatNotSupport]: 'ssPasswordFormatNotSupport',
  [ErrorCode_const.ContentFormatNotSupport]: 'ssContentFormatNotSupport',
  [ErrorCode_const.ContentLengthExceedLimit]: 'ssContentLengthExceedLimit',
  [ErrorCode_const.InvalidId]: 'ssInvalidId',
  [ErrorCode_const.ErrDBNoRecord]: 'ssErrDBNoRecord',
  [ErrorCode_const.Database]: 'ssDatabase',
  [ErrorCode_const.ProxyRebateStatusError]: 'ssProxyRebateStatusError',
  [ErrorCode_const.ParamValidationError]: 'ssParamValidationError',
  [ErrorCode_const.ErrSignWebTokenFail]: 'ssErrSignWebTokenFail',
  [ErrorCode_const.ErrValidWebTokenFail]: 'ssErrValidWebTokenFail',
  [ErrorCode_const.NotAllowClickSelfTag]: 'ssNotAllowClickSelfTag',
  [ErrorCode_const.PiggyBankBalanceNotEnough]: 'ssPiggyBankBalanceNotEnough',
  [ErrorCode_const.ErrAlreadyStreamer]: 'ssErrAlreadyStreamer',
  [ErrorCode_const.ErrLiveHasEnded]: 'ssErrLiveHasEnded',
  [ErrorCode_const.ErrorNotFindGame]: 'ssErrorNotFindGame',
  [ErrorCode_const.ErrorGameNotSupportCurrency]: 'ssErrorGameNotSupportCurrency',
  [ErrorCode_const.WithdrawAccountSurpassMaxNum]: 'ssWithdrawAccountSurpassMaxNum',
  [ErrorCode_const.ErrorGameNotSupportTryPlay]: 'ssErrorGameNotSupportTryPlay',
  [ErrorCode_const.DeviceRegisterFrequently]: 'ssDeviceRegisterFrequently',
  [ErrorCode_const.ActivityJoinConditionNotMeet]: 'ssActivityJoinConditionNotMeet',
  [ErrorCode_const.ActivityRewardReachedLimit]: 'ssActivityRewardReachedLimit',
  [ErrorCode_const.ActivityRouletteTicketNumInsufficient]: 'ssActivityRouletteTicketNumInsufficient',

  [ErrorCode_const.OtpPhoneFormatNotSupport]: 'ssOtpPhoneFormatNotSupport',
  [ErrorCode_const.OtpEmailFormatNotSupport]: 'ssOtpEmailFormatNotSupport',
  [ErrorCode_const.OtpCodeSendError]: 'ssOtpCodeSendError',
  [ErrorCode_const.OtpCodeSendTimeout]: 'ssOtpCodeSendTimeout',
  [ErrorCode_const.OtpCodeSendFrequently]: 'ssOtpCodeSendFrequently',
  [ErrorCode_const.OtpCodeSendLimit]: 'ssOtpCodeSendLimit',
  [ErrorCode_const.OtpCodeInvalid]: 'ssOtpCodeInvalid',
  [ErrorCode_const.IPRegisterFrequently]: 'ssIPRegisterFrequently',
  [ErrorCode_const.PhoneAlreadyBeenBind]: 'ssPhoneAlreadyBeenBind',
  [ErrorCode_const.PhoneIsNotUserBind]: 'ssPhoneIsNotUserBind',
  [ErrorCode_const.EmailAlreadyBeenBind]: 'ssEmailAlreadyBeenBind',
  [ErrorCode_const.EmailIsNotUserBind]: 'ssEmailIsNotUserBind',
  [ErrorCode_const.WithdrawAccountAlreadyBeenBind]: 'ssWithdrawAccountAlreadyBeenBind',
  [ErrorCode_const.ErrorCurrencyIsExist]: 'ssErrorCurrencyIsExist',

  [ErrorCode_const.ErrorCode_ExchangeCodeIsExist]: 'The redemption code already exists',
  [ErrorCode_const.ErrorCode_ErrorExchangeCodeIsUsed]: 'The redemption code has already been used.',
  [ErrorCode_const.ErrorCode_ErrorExchangeCodeIsExpired]: 'This redemption code is no longer valid.',
  [ErrorCode_const.ErrorCode_ErrorExchangeCodeIsInvalid]: 'The redemption code has exceeded the allowable usage limit.',
  [ErrorCode_const.ErrorCode_ErrorExchangeCodeIsNotExist]: 'The redemption code is incorrect.',
  [ErrorCode_const.ErrorCode_ErrorExchangeRisk]: 'The usage limit has been exceeded.',
  [ErrorCode_const.ErrorCode_ErrorExchangeUserType]: 'You cannot use this type of redemption code.',
  [ErrorCode_const.ErrorCode_ErrorExchangeArea]: 'You cannot use this type of redemption code.',
  [ErrorCode_const.ErrorCode_ErrorExchangeOsPlatform]: 'You cannot use this type of redemption code.',
  [ErrorCode_const.ErrorCode_ErrorExchangeChannel]: 'You cannot use this type of redemption code.',
  [ErrorCode_const.ErrorCode_ErrorExchangeExcept]: 'The redemption code has exceeded the allowable usage limit.',
  [ErrorCode_const.ErrorCode_ErrorExchangeCode]: 'The redemption code is incorrect.',
  [ErrorCode_const.HumanRecaptchaFailed]: 'Network error, Please try again.',
}
