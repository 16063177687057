export default defineNuxtPlugin({
  name: 'CHN',
  async setup({ $switchLocalePath, $localePath }) {
    if (process.server) return
    const params = getQueryParams()
    if (params.chn) {
      setLocal(StorageKey.CHN, params.chn)
    } else if (process.client && /^\/channel\/(\d+)/.test(window.location.pathname)) {
      setLocal(StorageKey.CHN, window.location.pathname.match(/^\/channel\/(\d+)/)[1])
    }
  },
})
