declare interface ModalRouteParams {
  modal?: string
  type?: string
  tab?: string
  replace?: boolean
}

export function openModalRoute({ modal, tab, type, replace }: ModalRouteParams, params?: any) {
  const router = useRouter()
  if (replace) {
    router.replace({ query: { modal, tab, type }, params: params || {} })
  } else {
    router.push({ query: { modal, tab, type }, params: params || {} })
  }
}

export function closeModalRoute() {
  const router = useRouter()
  router.replace({ path: router.currentRoute.value.path })
}
