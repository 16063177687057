import type { ValidateError } from 'async-validator'
import mitt from 'mitt'

export type ScrollToEvent = {
  top?: number
  behavior?: 'auto' | 'smooth'
}

export type TrackOption<Key extends keyof TrackEvents> = {
  evname: Key
  params: TrackEvents[Key]
}

export type Events = {
  back: string
  storage: null
  validate: ValidateError[] | null
  reload: null
  'reload-user-data': null
  'scroll-to': number | ScrollToEvent | undefined
  'scroll-end': number
  'scroll-saved-positoin': string | undefined
  'manual-choosed-cpn': undefined
  'show-preview-image': undefined
  scroll: number
  'ev-growl': Growl
  'ev-dialog-open': DialogData<any>
  'ev-dialog-close': boolean
  'ev-show-ani-spin': null
  'ev-show-ani-checkin': null
  'ev-show-ani-balance': null
  'ev-show-ani-prop': null
  'ev-show-ani-goal': null
  'act-face-punch-end': undefined
  'ev-track': TrackOption<any>
}

export const emitter = mitt<Events>()

emitter.on('*', (type, event) => Log.debug(type, event))

type PageMessage = 'signup_success' | 'signin_success' | 'invite_link_visit'
type PageMessages = Record<PageMessage, Record<string, string>>

const pageMessages: PageMessages = getLocalDESJson(StorageKey.PAGE_MESSAGES) || {}

// 生产异步消息 供其他页面消费
export const producePageMsg = (name: PageMessage, payload: Record<string, string>) => {
  pageMessages[name] = payload
  setLocalDESJson(StorageKey.PAGE_MESSAGES, pageMessages)
}

// 消费异步消息 处理页面跳转前无法发送的请求
export const consumPageMsg = (name: PageMessage, cb: (payload: Record<string, string>) => void) => {
  if (!Object.keys(pageMessages).includes(name)) return
  const payload = pageMessages[name]
  delete pageMessages[name]
  setLocalDESJson(StorageKey.PAGE_MESSAGES, pageMessages)
  cb(payload)
}
