export default defineNuxtPlugin({
  name: 'SW',
  async setup(nuxtApp) {
    if (process.server) return

    // const _isSafari = /safari|applewebkit/i.test(navigator.userAgent)

    const UPDATE_INTERVAL = 10*60*1000 //60 * 60 * 1000 // 离开10分钟后更新
    let lastActiveTime = Number(getLocal(StorageKey.LAST_ACTIVE_TIME) || 0)
    window.addEventListener('load', async () => {

      window.addEventListener('pageshow', (event) => {
        if (event?.persisted) {
          // 从缓存恢复时，检查是否需要更新，而不是立即刷新
          checkForUpdates()
        }
      })
      // pwa更新页面
      document.addEventListener('visibilitychange', async () => {
        const now = Date.now()
        if (document.hidden) {  
          lastActiveTime = now
          setLocal(StorageKey.LAST_ACTIVE_TIME, now)
        } else if (now - lastActiveTime > UPDATE_INTERVAL) {
          checkForUpdates()
        }
      })
      const { version } = useRuntimeConfig().public
      // 检查 Service Worker 版本是否匹配
      const lastversion = getLocal('swv')
      if (lastversion !== version) {
        setLocal('swv', version)
        // 有旧版本时才注销并刷新页面
        if (!!lastversion) {
           const registrations = await navigator?.serviceWorker?.getRegistrations()
           for (const registration of registrations || []) {
             await registration?.unregister()
           }
           // 注销后刷新页面，确保清洁状态
           window.location.reload()
        }
      } else {
        // 如果版本匹配，仅检查更新
        checkForUpdates()
      }

      // 检查 Service Worker 是否在工作
      // if ('serviceWorker' in navigator) {
      //   try {
      //     const registration = await navigator.serviceWorker.getRegistration()
      //     if (registration && registration.active) {
      //       console.log('Service Worker 已在工作')
      //     } else {
      //       console.log('Service Worker 未在工作')
      //     }
      //   } catch (error) {
      //     console.error('获取 Service Worker 注册信息失败:', error)
      //   }
      // } else {
      //   console.log('浏览器不支持 Service Worker')
      // }
    })
    function checkForUpdates() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.addEventListener('message', (event) => {
          if (event.data.type === 'UPDATE_AVAILABLE') {
            // 提示用户更新
            notifyUserAboutUpdate()
          }
        })
        navigator.serviceWorker.ready.then(async (registration) => {
          try {
            await registration.update()
            if (registration.waiting) {
              // 向 Service Worker 发送消息,提示有新版本可用
              registration.waiting.postMessage({ type: 'UPDATE_AVAILABLE' })
            }
          } catch (error) {
            // console.error('Service Worker 更新失败:', error)
          }
        })
      } else {
        // 浏览器不支持 Service Worker
        // console.log('您的浏览器不支持 Service Worker')
      }
    }

    function notifyUserAboutUpdate() {
      // console.log('新版本可用。是否刷新以更新？')
      // window.location.reload()
    }

    function isStandalone() {
      return window.matchMedia('(display-mode: standalone)').matches
    }
  },
})
