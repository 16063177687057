export default defineNuxtPlugin({
  name: 'Fingerprintjs',
  async setup(nuxtApp) {
    if (process.server) return
    const appConfig = useAppConfig()
    let deviceid = getLocalBase64(StorageKey.DEVICE_ID)
    if (!deviceid) {
      // const fp = await FingerprintJS.load()
      // const result = await fp.get()
      // appConfig.fingerprint = result.visitorId
      deviceid = randomStr(12) //result.visitorId + '_' + randomStr(12)
      setLocalBase64(StorageKey.DEVICE_ID, deviceid)
    }
    appConfig.fingerprint = deviceid
    updateAppConfig(appConfig)
  },
})
