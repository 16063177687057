export const dialogs: Ref<DialogData<any>[]> = ref([])
let idx = 0

export function openDialog<T extends Component>(comp: T, props?: ComponentProps<T>) {
  if (process.server) return
  dialogs.value.push({ id: idx++, comp: markRaw(comp), props })
  window.history.pushState({ type: 'dialog' }, null, document.URL) // 滑动后退时关闭对话框
}

// 关闭所有
export function clearDialog() {
  dialogs.value = []
}

// 延迟清理关闭后的弹窗路由
const clearDialogHistory = useDebounceFn(() => {
  return !dialogs.value.length && history.state?.type === 'dialog' && !history.state?.current && history.go(-1)
}, 500)

// 逐层关闭
export function closeDialog() {
  dialogs.value.pop()
  if (dialogs.value.length === 0) clearDialogHistory()
}
