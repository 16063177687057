export default defineNuxtPlugin({
  name: 'ErrorHandler',
  setup(nuxtApp) {
    if (nuxtApp.ssrContext) {
      return
    }

    function handleError(e) {
      if (e?.statusCode === 401) {
        removeLocal(StorageKey.TOKEN)
        Log.debug('401 reload page.')
        window.location.reload()
      } else if (Object.values(ErrorCode_const).includes(e?.code) && e?.code !== ErrorCode_const.NO && !e.handled) {
        // 有些程序错误code是0，且在业务中0代表成功，这里需要过滤掉
        if (e.code === ErrorCode_const.ServerStop) {
          // window.location.reload()
        } else if (e.code === ErrorCode_const.UserUnLogin) {
          removeLocal(StorageKey.TOKEN)
          Log.debug('UserUnLogin reload page.')
          window.location.reload()
        } else {
          nError(ErrorTips[e.code], true)
        }
      } else {
        Log.error(e)
      }
    }

    ;(function (win, doc) {
      if (!win.addEventListener) return
      win.addEventListener('unhandledrejection', (e) => {
        e.preventDefault()
        if (e.reason) handleError(e.reason)
        else {
          Log.error(e)
        }
      })
    })(window, document)

    // Also possible
    nuxtApp.hook('vue:error', (error: any, instance, info) => {
      handleError(error)
      error.handled = true
      return
    })
  },
})
