import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthCredential,
  SignInMethod,
  TwitterAuthProvider,
  getAuth,
  getRedirectResult,
  linkWithCredential,
  onAuthStateChanged,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth'

const supportedPopupSignInMethods = [SignInMethod.GOOGLE, SignInMethod.FACEBOOK, SignInMethod.TWITTER]

function getProvider(providerId: string) {
  switch (providerId) {
    case 'google':
    case SignInMethod.GOOGLE:
      return GoogleAuthProvider
    case 'twitter':
    case SignInMethod.TWITTER:
      return TwitterAuthProvider
    case 'facebook':
    case SignInMethod.FACEBOOK:
      return FacebookAuthProvider
    default:
      throw new Error(`No provider implemented for ${providerId}`)
  }
}

async function handleThirdSignIn(loginMethod: string, accessToken: string) {
  const { getInviteCode } = useGlobalStore()
  const { saveUserToken } = useUserStore()

  const { token, basicInfo, isNewUser, newcomerGuidance } = await request(
    userLoginThird({
      type: loginMethod,
      idToken: accessToken,
      deviceNo: useAppConfig().fingerprint as string,
      inviteCode: getInviteCode(),
      simulatorSign: false,
    })
  )
  // 新人引导
  if (isNewUser || newcomerGuidance) {
    setLocal(StorageKey.IS_REGISTERED, true)
    newcomerGuidance && setLocalDESJson(StorageKey.NEWCOMER_GUIDANCE, newcomerGuidance)
  }
  saveUserToken(token, true)
  // producePageMsg(isNewUser ? 'signup_success' : 'signin_success', { name: basicInfo?.email })
  EventTrack(isNewUser ? 'signup_success' : 'signin_success', { name: basicInfo?.email })
  nextTick(() => jumRootUrl(true))
}

export default defineNuxtPlugin({
  name: 'Firebase',
  dependsOn: ['Fingerprintjs'],
  async setup(nuxtApp) {
    const firebaseConfig = {
      authDomain: import.meta.env.VITE_OAUTH_CONF_HOST || window.location.host,
      apiKey: import.meta.env.VITE_OAUTH_CONF_APIKEY, //'AIzaSyCuw1pDnuuzVDBET7tYIaf8QYnsdnOlDMk',
      projectId: import.meta.env.VITE_OAUTH_CONF_PROJECTID, //'live-57102',
      storageBucket: import.meta.env.VITE_OAUTH_CONF_STORAGEBUCKET, //'live-57102.appspot.com',
      messagingSenderId: import.meta.env.VITE_OAUTH_CONF_MESSAGINGSENDERID, //'183440956215',
      appId: import.meta.env.VITE_OAUTH_CONF_APPID, //'1:183440956215:web:cf389d504843c59e1958b2',
      measurementId: import.meta.env.VITE_OAUTH_CONF_MEASUREMENTID, //'G-DQYBEVCZ9Z',
    }
    const firebase = initializeApp(firebaseConfig)
    const analytics = getAnalytics(firebase)
    const auth = getAuth(firebase)
    const providerGoogle = new GoogleAuthProvider()
    const providerTwitter = new TwitterAuthProvider()
    const providerFacebook = new FacebookAuthProvider()
    function getProviderInstance(providerId: string) {
      switch (providerId) {
        case 'google':
        case SignInMethod.GOOGLE:
          return providerGoogle
        case 'twitter':
        case SignInMethod.TWITTER:
          return providerTwitter
        case 'facebook':
        case SignInMethod.FACEBOOK:
          return providerFacebook
        default:
          throw new Error(`No provider implemented for ${providerId}`)
      }
    }

    async function handleFirbaseLogin(type: string) {
      try {
        const provider = getProviderInstance(type)
        let result = await signInWithPopup(auth, provider)
        // console.log('credential', result)
        let credential = getProvider(type).credentialFromResult(result)
        // console.log('credential', credential)
        await handleThirdSignIn(
          type,
          type === 'twitter' ? `${credential.accessToken}&${credential.secret}` : credential.accessToken
        )
      } catch (err) {
        Log.error(err)
        const email = err.email || err.customData.email
        if (!email) throw err
        if (err.code === 'auth/account-exists-with-different-credential') {
          const methods = err.customData._tokenResponse.verifiedProvider
          // const methods = await fetchSignInMethodsForEmail(auth, email)
          const firstPopupProviderMethod = methods.find((p) => supportedPopupSignInMethods.includes(p as any))

          if (!firstPopupProviderMethod) {
            throw new Error(`Your account is linked to a provider that isn't supported.`)
          }

          const linkedProvider = getProviderInstance(firstPopupProviderMethod)
          linkedProvider.setCustomParameters({ login_hint: email })
          const cred = getProvider(type).credentialFromError(err)
          setSession(StorageKey.THIRD_CRED, JSON.stringify(cred))

          await signInWithRedirect(auth, linkedProvider)
        }
      }
    }

    nuxtApp.vueApp.provide('firebase', {
      app: firebase,
      analytics,
      auth,
      providerGoogle,
      providerTwitter,
      providerFacebook,
      handleFirbaseLogin,
    })
    nuxtApp.provide('firebase', {
      app: firebase,
      analytics,
      auth,
      providerGoogle,
      providerTwitter,
      providerFacebook,
      handleFirbaseLogin,
    })
    onAuthStateChanged(auth, handleRedirect)
  },
})

async function handleRedirect(currUser) {
  if (!currUser) return
  let result
  try {
    result = await getRedirectResult(currUser.auth)
  } catch (err) {
    Log.error(err)
    return
  }
  const savedCredential = getSession(StorageKey.THIRD_CRED)

  if (!savedCredential) return

  // console.log(savedCredential, 'savedCredential===============================================')
  // console.log(currUser, 'user===============================================')

  const cred = OAuthCredential.fromJSON(savedCredential)
  const credential = getProvider(cred.providerId).credential(cred.accessToken, cred.secret)
  await linkWithCredential(result.user, credential)
  removeSession(StorageKey.THIRD_CRED)
  // console.log(credential, 'credential ===============================================')
  // console.log(cred, 'cred===============================================')
  handleThirdSignIn(currUser.providerId.split('.')[0], credential.accessToken + '&' + credential.secret)
}
