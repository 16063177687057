export type Growl = {
  id?: number
  time?: number
  type: 'error' | 'success' | 'warning' | 'info'
  message: string
  translate?: boolean
}
export function nError(message: string, translate: boolean = false, time?: number) {
  emitter.emit('ev-growl', { type: 'error', message, translate, time })
}
export function nSuccess(message: string, translate: boolean = false, time?: number) {
  emitter.emit('ev-growl', { type: 'success', message, translate, time })
}
export function nWarn(message: string, translate: boolean = false, time?: number) {
  emitter.emit('ev-growl', { type: 'warning', message, translate, time })
}
export function nInfo(message: string, translate: boolean = false, time?: number) {
  emitter.emit('ev-growl', { type: 'info', message, translate, time })
}
