<template>
  <section v-if="showLoading" class="fixed-mask flex-c !z-[--zindex-growl] !bg-[rgba(0,0,0,0.1)]">
    <main
      class="relative m-auto bg-[rgba(0,0,0,0.8)] rounded inline-flex shadow-[0_0_8px_0_rgba(0,0,0,0.3)] p-4 sm:p-4">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>

        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </main>
  </section>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    throttle?: number
    duration?: number
  }>(),
  {
    throttle: 300,
    duration: 20000,
  }
)

const { isLoading: globalLoading } = storeToRefs(useGlobalStore())

// FIXME: 游戏界面刷新页面不结束
const { isLoading, finish } = useLoadingIndicator({
  duration: props.duration,
  throttle: props.throttle,
  // This is how progress is calculated by default
  estimatedProgress: (duration, elapsed) => {
    return (2 / Math.PI) * 100 * Math.atan(((elapsed / duration) * 100) / 50)
  },
})

onMounted(() => {
  // 防止某些情况下，页面刷新时，loading状态卡住一直存在
  finish({ force: true })
})

const showLoading = computed(() => globalLoading.value || isLoading.value)

watchEffect(() => {
  Log.debug(
    'loading info:',
    'Loading:' + isLoading.value ? 'Yes' : 'No',
    'Global Loading:' + globalLoading.value ? 'Yes' : 'No'
  )
})
</script>

<style lang="postcss" scoped>
.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
  box-sizing: border-box;
}
.lds-spinner {
  color: currentColor;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.lds-spinner div {
  transform-origin: 12px 12px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 4%;
  left: 46%;
  width: 2px;
  height: 5px;
  border-radius: 20%;
  background: currentColor;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
