export const useActConfStore = defineStore('ActConfStore', () => {
  const actSysList: Ref<activity_system_type[]> = ref([])

  async function initActConf() {
    const response = await request(activitySystemList({}))
    actSysList.value = response?.list || []
  }

  function isActEnabled(actType: activity_system_type) {
    return actSysList.value?.indexOf(actType) > -1
  }
  return {
    actSysList,
    initActConf,
    isActEnabled,
  }
})

export const useActRewardStore = defineStore('ActReward', () => {
  const activityRewardStatusMap: Ref<Partial<Record<activity_system_type, boolean>>> = ref({})
  return { activityRewardStatusMap }
})

export const useActRechargeBonusStore = defineStore('ActRechargeBonusStore', () => {
  const rechargeBonus = ref()
  function setRechargeBonus(value: { isFirst: boolean; currencyId: number; bonus: string }) {
    rechargeBonus.value = value
  }
  function clearRechargeBonus() {
    rechargeBonus.value = null
  }

  const rechargeRule: Ref<Iactivity_recharge_rule_ratio_res | undefined> = ref()
  const { userCurrencyId } = storeToRefs(useUserStore())
  async function initRechargeRule() {
    const response = await request(activityRechargeRuleRatio({ currencyId: userCurrencyId.value }))
    rechargeRule.value = response
  }
  return {
    rechargeBonus,
    setRechargeBonus,
    clearRechargeBonus,
    rechargeRule,
    initRechargeRule,
  }
})

// 每日签到
export const useActSignInStore = defineStore('ActSignInStore', () => {
  const signInInfo: Ref<IActivityCycleSignInConfResp> = ref()
  const signInDays: Ref<IRewardConfBase[]> = ref([])
  const signInDayId: Ref<string> = ref('')

  const signInDayIndex = computed(() => {
    return signInDays.value.findIndex(({ rewardId }) => rewardId === signInDayId.value) || 0
  })

  const signInDayInfo = computed(() => {
    return signInDays.value.find(({ rewardId }) => rewardId === signInDayId.value) || {}
  })
  
  async function initActSignIn() {
    const response = await request(activityCycleSignInConf({}))
    signInInfo.value = response
    signInDays.value = response?.rewardConf || []
    signInDayId.value = response?.rewardId || signInDays.value[0]?.rewardId
  }

  return {
    signInInfo,
    signInDays,
    signInDayId,
    signInDayIndex,
    signInDayInfo,
    initActSignIn,
  }
})

// 周卡月卡
export const useActWMSubsStore = defineStore('ActWMSubsStore', () => {
  const { isLogin, userInfo } = storeToRefs(useUserStore())
  const newForWMSubs = ref(true)
  watchEffect(() => {
    if (isLogin.value) {
      newForWMSubs.value = Boolean(getLocal(StorageKey.NEW_FOR_WMSUBS_PREFIX + '_' + userInfo.value?.userId))
    }
  })
  function setNewForWMSubs(isnew: boolean) {
    newForWMSubs.value = isnew
    if (!isLogin.value) return
    if (isnew) {
      setLocal(StorageKey.NEW_FOR_WMSUBS_PREFIX + '_' + userInfo.value?.userId, '1')
    } else {
      removeLocal(StorageKey.NEW_FOR_WMSUBS_PREFIX + '_' + userInfo.value?.userId)
    }
  }

  const wmSubsInfo: Ref<IRechargeCardGoodsInfoResp> = ref()

  const wmSubsInfoStatus: ComputedRef<{
    isSubscribe: boolean
    isReceive: boolean
    allBonus: number
  }> = computed(() => {
    let isSubscribe = false // 是否订阅
    let isReceive = true // 今日是否领取
    let allBonus = 0 // 总奖励

    if (wmSubsInfo.value?.infos) {
      wmSubsInfo.value.infos.forEach((item) => {
        if (item.status === '1') isSubscribe = true
        if (Number(item.todayBonus) > 0) isReceive = false
        allBonus += Number(item.todayBonus) || 0
      })
    }

    return { isSubscribe, isReceive, allBonus }
  })

  async function initWMSubsActInfo() {
    const { userCurrencyId } = useUserStore()
    const response = await request(
      activityRechargeCardGoods({ currencyId: String(userCurrencyId), channelId: String(getChannel(1)) })
    )
    wmSubsInfo.value = response || undefined
  }

  return {
    newForWMSubs,
    setNewForWMSubs,
    
    wmSubsInfo,
    wmSubsInfoStatus,
    initWMSubsActInfo,
  }
})

// 打脸图集合
export const useActFPCollStore = defineStore('ActFPCollStore', () => {
  const { isLogin, userInfo } = storeToRefs(useUserStore())
  const fpJumpTagId: Ref<string> = ref()
  const fpCollection: Ref<IHingeConf[]> = ref()
  const filtedFBCollection = computed(() => {
    const { phone, email } = userInfo.value || {}
    return (
      fpCollection.value?.filter((item) => {
        if (item.hingeType !== HingeTypeCode_const.HingeTypeFunc) return true
        switch (item.hingeSubType) {
          case HingeSubTypeCode_const.HingeSubTypeBindEmail:
            return !email
          case HingeSubTypeCode_const.HingeSubTypeBindPhone:
            return !phone
          case HingeSubTypeCode_const.HingeSubTypeAppLogin:
            return !isLogin.value
          default:
            return true
        }
      }) || []
    )
  })
  async function initActFPColl() {
    const response = await request(basicHingeConfInfo({}))
    fpCollection.value = response.hingeConf || []
    fpJumpTagId.value = response.goToPlay
    // fpCollection.value = [
    //   {
    //     title: '1111111',
    //     img: '',
    //     hingeType: HingeTypeCode_const.HingeTypeFunc,
    //     hingeSubType: HingeSubTypeCode_const.HingeSubTypeBindEmail,
    //     id: '1',
    //     hot: '1',
    //   },
    // ]
  }
  async function setFPRead(id: any) {
    if (!isLogin.value) return
    await request(basicHingeClick({ id }))
    initActFPColl()
  }
  return {
    fpJumpTagId,
    fpCollection,
    filtedFBCollection,
    setFPRead,
    initActFPColl,
  }
})

// 移动端活动中心
export const useActActivityCenterStore = defineStore('ActActivityCenterStore', () => {
  const activtiyInfo = ref<Array<IReceiveCenterActivityInfo>>()

  async function initActActivityCenter() {
    const { userCurrencyId } = useUserStore()
    const response = await request(activityReceiveCenterInfo({ currencyId: userCurrencyId }))
    activtiyInfo.value = response?.list || []
  }

  return { activtiyInfo, initActActivityCenter }
})
