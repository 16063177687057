export async function shareInviteCode() {
  if (!navigator.canShare) {
    nError('your-browser-doesn-t-support-the-web-share-api', true)
    return
  }

  const response = await request(activityInviteCodeInfo({}))
  try {
    await navigator.share({ url: response.inviteUrl, title: response.inviteText })
    await request(activityInviteEvent({}))
    return true
  } catch (err) {
    Log.error(err)
  }
  return false
}

export async function shareData(data: Record<string, any>) {
  if (!navigator.canShare) {
    nError('your-browser-doesn-t-support-the-web-share-api', true)
    return
  }

  try {
    await navigator.share(data)
    return true
  } catch (err) {
    Log.error(err)
  }
  return false
}

export function copyLink(link: string) {
  copyText(link)
  nSuccess('copy-succeeded', true)
}
