const DEFAULT_WIDTH = 700
const DEFAULT_HEIGHT = 600
const TARGET_BLANK = '_blank'
const FIREFOX_EMPTY_URL = 'http://localhost'

const BASE_POPUP_OPTIONS: {
  location?: string
  resizable?: string
  statusbar?: string
  toolbar?: string
  scrollbars?: string
} = {
  location: 'yes',
  resizable: 'yes',
  statusbar: 'yes',
  toolbar: 'no',
}
type WinFeatures = {
  width?: number
  height?: number
}

function _isWindClosed(newWin: Window) {
  return !newWin || newWin.closed || typeof newWin.closed == 'undefined'
}

function _open(url, name, features: WinFeatures | null) {
  const hasFeature = features !== null // Patch: features为null时在新标签打开窗口，否则弹出窗口
  features = Object.assign({ width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT }, features || {})
  const top = Math.max((window.screen.availHeight - features.height || 0) / 2, 0).toString()
  const left = Math.max((window.screen.availWidth - features.width || 0) / 2, 0).toString()
  let target = ''
  const options = Object.assign(Object.assign({}, BASE_POPUP_OPTIONS), {
    width: features.width.toString(),
    height: features.height.toString(),
    top,
    left,
  })
  // Chrome iOS 7 and 8 is returning an undefined popup win when target is
  // specified, even though the popup is not necessarily blocked.
  const ua = getUA().toLowerCase()
  if (name) {
    target = _isChromeIOS(ua) ? TARGET_BLANK : name
  }
  if (_isFirefox(ua)) {
    // Firefox complains when invalid URLs are popped out. Hacky way to bypass.
    url = url || FIREFOX_EMPTY_URL
    // Firefox disables by default scrolling on popup windows, which can create
    // issues when the user has many Google accounts, for instance.
    options.scrollbars = 'yes'
  }
  const optionsString = Object.entries(options).reduce((accum, [key, value]) => `${accum}${key}=${value},`, '')
  if (_isIOSStandalone(ua) || _isSafari(ua)) {
    const newWin = window.open(url || '', target !== '_self' ? TARGET_BLANK : target, !hasFeature ? '' : optionsString)
    return !_isWindClosed(newWin)
  }

  // about:blank getting sanitized causing browsers like IE/Edge to display
  // brief error message before redirecting to handler.
  return new Promise<boolean>((resolve) => {
    setTimeout(() => {
      const newWin = window.open(url || '', target, !hasFeature ? '' : optionsString)
      try {
        newWin.focus()
      } catch (e) {
      } finally {
        resolve(!!getSession(StorageKey.S2S_PARAMS) || !_isWindClosed(newWin))
      }
    }, 300)
  })
}

// function openAsNewWindowIOS(url, target) {
//   const el = document.createElement('a')
//   el.href = url
//   el.target = target || TARGET_BLANK
//   const click = document.createEvent('MouseEvent')
//   click.initMouseEvent('click', true, true, window, 1, 0, 0, 0, 0, false, false, false, false, 1, null)
//   el.dispatchEvent(click)
// }

// 打开窗口
export function openWindow(url: string, name: string = '', popup: boolean = true): boolean | Promise<boolean> {
  // features: string = 'height=600, width=700, top=265,left=645, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
  return _open(url, name, popup ? {} : null)
}

export function getUA() {
  if (typeof navigator !== 'undefined' && typeof navigator['userAgent'] === 'string') {
    return navigator['userAgent']
  } else {
    return ''
  }
}

function _getBrowserName(userAgent) {
  const ua = userAgent.toLowerCase()
  if (ua.includes('opera/') || ua.includes('opr/') || ua.includes('opios/')) {
    return 'Opera' /* BrowserName.OPERA */
  } else if (_isIEMobile(ua)) {
    // Windows phone IEMobile browser.
    return 'IEMobile' /* BrowserName.IEMOBILE */
  } else if (ua.includes('msie') || ua.includes('trident/')) {
    return 'IE' /* BrowserName.IE */
  } else if (ua.includes('edge/')) {
    return 'Edge' /* BrowserName.EDGE */
  } else if (_isFirefox(ua)) {
    return 'Firefox' /* BrowserName.FIREFOX */
  } else if (ua.includes('silk/')) {
    return 'Silk' /* BrowserName.SILK */
  } else if (_isBlackBerry(ua)) {
    // Blackberry browser.
    return 'Blackberry' /* BrowserName.BLACKBERRY */
  } else if (_isWebOS(ua)) {
    // WebOS default browser.
    return 'Webos' /* BrowserName.WEBOS */
  } else if (_isSafari(ua)) {
    return 'Safari' /* BrowserName.SAFARI */
  } else if ((ua.includes('chrome/') || _isChromeIOS(ua)) && !ua.includes('edge/')) {
    return 'Chrome' /* BrowserName.CHROME */
  } else if (_isAndroid(ua)) {
    // Android stock browser.
    return 'Android' /* BrowserName.ANDROID */
  } else {
    // Most modern browsers have name/version at end of user agent string.
    const re = /([a-zA-Z\d\.]+)\/[a-zA-Z\d\.]*$/
    const matches = userAgent.match(re)
    if ((matches === null || matches === void 0 ? void 0 : matches.length) === 2) {
      return matches[1]
    }
  }
  return 'Other' /* BrowserName.OTHER */
}
function _isFirefox(ua = getUA()) {
  return /firefox\//i.test(ua)
}
function _isSafari(userAgent = getUA()) {
  const ua = userAgent.toLowerCase()
  return ua.includes('safari/') && !ua.includes('chrome/') && !ua.includes('crios/') && !ua.includes('android')
}
function _isChromeIOS(ua = getUA()) {
  return /crios\//i.test(ua)
}
function _isIEMobile(ua = getUA()) {
  return /iemobile/i.test(ua)
}
function _isAndroid(ua = getUA()) {
  return /android/i.test(ua)
}
function _isBlackBerry(ua = getUA()) {
  return /blackberry/i.test(ua)
}
function _isWebOS(ua = getUA()) {
  return /webos/i.test(ua)
}
function _isIOS(ua = getUA()) {
  return /iphone|ipad|ipod/i.test(ua) || (/macintosh/i.test(ua) && /mobile/i.test(ua))
}
function _isIOS7Or8(ua = getUA()) {
  return /(iPad|iPhone|iPod).*OS 7_\d/i.test(ua) || /(iPad|iPhone|iPod).*OS 8_\d/i.test(ua)
}
function _isIOSStandalone(ua = getUA()) {
  var _a
  return _isIOS(ua) && !!((_a = window.navigator) === null || _a === void 0 ? void 0 : _a.standalone)
}
