<template>
  <InitLoader />
  <NuxtPwaManifest />
  <NuxtLayout v-if="!isError && isReady">
    <div :key="key">
      <!-- :pageKey="(route) => (console.log(route), route.matched[0].path)" -->
      <NuxtPage />
    </div>
  </NuxtLayout>

  <div @scroll.stop>
    <!-- <Transition name="load-exit">
      <SplashLoader v-show="isLoading" />
    </Transition> -->
    <LazyMaintainPage v-show="!isLoading && isError" :msg="errorMsg" />

    <div class="fixed hidden opacity-0">{{ buildTime }}</div>
  </div>
</template>
<script setup lang="ts">
import placeholderImg from '@/assets/images/placeholder.svg?url';
updateAppConfig({ placeholderImg })

if (!!import.meta.env.VITE_API_PREFIX) {
  useHead({
    link: [{ rel: 'preconnect', href: import.meta.env.VITE_API_PREFIX + '/basic/config/param/list' }],
  })
}

const i18n = useI18n()
useHead({
  meta: [{ name: 'Content-Language', content: i18n.locale.value }],
})

const { isError, isLoading, isReady, errorMsg, tdkPathMap, buildTime } = storeToRefs(useGlobalStore())
const key = ref(0)
const { initUserToken } = useUserData()
const { initBasicConfigParamList, initGameTdkDetail, setBuildTime } = useGlobalStore()
const route = useRoute()
if (process.server && !process.dev) {
  setBuildTime(Date.now())
  await initGameTdkDetail(route.path, i18n.locale.value).catch(Log.error)
}
const tdkdetail = tdkPathMap.value.get(route.path)
// console.log(route.path, i18n.locale.value, tdkdetail)
if (tdkdetail) {
  useHeadSafe({
    title: tdkdetail.title,
    meta: [
      { content: tdkdetail.keywords, name: 'keywords' },
      { content: tdkdetail.description, name: 'description' },
    ],
  })
}

Log.debug('Before Ready Info:', 'Ready:' + isLoading.value ? 'Yes' : 'No')
await Promise.all([initBasicConfigParamList(), initUserToken()])
  .then(() => {
    nextTick(() => {
      isReady.value = true
    })
    Log.debug('Ready Info:', 'Ready:' + isLoading.value ? 'Yes' : 'No')
  })
  .catch((e) => {
    e.stack && Log.error(e.stack)
    isLoading.value = false
    isError.value = true
    if (e.code === ErrorCode_const.ServerStop) {
      errorMsg.value = e.message
    } else {
      errorMsg.value = (e.code ? i18n.t(ErrorTips[e.code]) : e.msg) || ''
    }
  })

emitter.on('reload', async () => {
  Log.info('trigger reload...')
  // isLoading.value = true
  // await nextTick()
  // 刷新数据
  // refreshNuxtData().catch(Log.error)
  key.value++
  await nextTick()
  isLoading.value = false
})

// if (process.client) {
//   watchEffect(() => {
//     nextTick(() => {
//       if (isLoading.value) {
//         document.documentElement.style.overflow = 'hidden'
//       } else {
//         document.documentElement.style.overflow = ''
//       }
//     })
//   })
// }
</script>

<style lang="postcss">
@import '@/assets/css/main.css';
</style>
