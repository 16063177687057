<script setup lang="ts">
import type { NuxtError } from '#app';

const props = defineProps({
  error: Object as () => NuxtError
})
const localePath = useLocalePath()
const handleError = () => clearError({ redirect: localePath('/') })
</script>

<template>
  <div>
    <NuxtLayout>
      <div class="flex flex-col items-center pt-20 sm:pt-52 text-white">
        <img v-if="error.statusCode === 404" src="@/assets/images/404.svg" alt="404" class="h-52.5 w-auto mb-13" />
        <h2 class="text-9 font-semibold leading-9 drop-shadow-[0px_4px_4px_rgba(0,0,0,.25)] mb-5">
          {{ $t('error.oops-n-error', [error.statusCode]) }}
        </h2>
        <span class="text-lg leading-4 mb-5">{{ $t('error.try-go-back-to-our-homepage') }}</span>
        <ui-button type="primary" class="min-w-46.5" @click="handleError">{{ $t('error.homepage') }}</ui-button>
      </div>
    </NuxtLayout>
  </div>
</template>