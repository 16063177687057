export async function batchRequestGameOprConf(
  configs: GameOperationConfChild[],
  oprPageSize: number = 24,
  cache: boolean = true
) {
  const reqs = []
  const { assign } = Object
  for (const child of configs) {
    if (child.type === 'provider') {
      reqs.push({
        data: gamePlatformList({}),
        cb: (response: Igame_platform_list_res) => assign(child, { data: response?.platforms || [] }),
      })
    } else if (child.type === 'tags') {
      reqs.push({
        data: gameTagList({}),
        cb: (response: Igame_tag_list_res) => assign(child, { data: response?.tags || [] }),
      })
    } else if (child.type === 'category_type') {
      reqs.push({
        data: gameListType({ pageIndex: 1, pageSize: oprPageSize, gameType: child.param?.type }),
        cb: (response: Igame_list_type_res) => assign(child, { data: response?.games, total: response?.itemCount }),
      })
    } else if (child.type === 'category_tag') {
      reqs.push({
        data: gameListTag({ pageIndex: 1, pageSize: oprPageSize, tagId: child.param?.tagId }),
        cb: (response: Igame_list_type_res) => assign(child, { data: response?.games, total: response?.itemCount }),
      })
    } else if (child.type === 'collect_type') {
      reqs.push({
        data: gameCollectList({ pageIndex: 1, pageSize: oprPageSize }),
        cb: (response: Igame_collect_list_res) =>
          assign(child, { data: response?.list?.map((item) => item.game) || [] }),
      })
    } else if (child.type === 'history_type') {
      reqs.push({
        data: gameListRecently({}),
        cb: (response: Igame_list_recently_res) => assign(child, { data: response?.games || [] }),
      })
    } else if (child.type === 'big_winner') {
      reqs.push({
        data: gameRankRaceBiggestGame({}),
        cb: (response: Igame_rank_race_biggest_game_res) => assign(child, { data: response?.list || [] }),
      })
    }
  }

  const responses = await batchRequest(
    reqs.map((item) => item.data),
    {},
    cache
  )
  for (let i = 0; i < reqs.length; i++) {
    reqs[i].cb((responses && responses[i]?.data) || {})
  }
}
