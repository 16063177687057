import dayjs from 'dayjs'
import { padStart } from './string'

// to fix date error on safari
export function newDate(date?: dayjs.ConfigType) {
  return dayjs(date).toDate()
}

export function newDayjs(date?: dayjs.ConfigType) {
  return dayjs(date)
}

export function lastMonth(date?: dayjs.ConfigType) {
  return dayjs(date).add(-1, 'month').toDate()
}

export function padTo2Digits(num: number, len: number = 2, padstr: string = '0') {
  return padStart(num, len, padstr)
}

export function getStartMonthTime(time: number | string) {
  const now = newDate(time)
  const start = newDate(0)
  start.setFullYear(now.getFullYear())
  start.setMonth(now.getMonth())
  start.setDate(1)
  return start.getTime()
}

export function getEndMonthTime(time: number | string) {
  const now = newDate(time)
  const start = newDate(0)
  start.setFullYear(now.getFullYear())
  start.setMonth(now.getMonth() + 1)
  start.setDate(1)
  return start.getTime() - 1
}

export function getStartDateTime(time: number) {
  if (!time) return time
  return Math.floor(time / (24 * 60 * 60 * 1000)) * 24 * 60 * 60 * 1000
}

export function getEndDateTime(time: number) {
  if (!time) return time
  return (Math.floor(time / (24 * 60 * 60 * 1000)) + 1) * 24 * 60 * 60 * 1000 - 1
}

export function getMonthTime(time: number) {
  if (!time) return time
  return Math.floor(time / (24 * 60 * 60 * 1000)) * 24 * 60 * 60 * 1000
}

// 格式化日期 yyyy/MM/dd
export function formatDate(date: Date | number, defaultVal: string = '') {
  if (!date) return defaultVal || ''
  if (typeof date === 'number') {
    if (date <= 0) return defaultVal || ''
    date = newDate(date)
  }
  return `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('/')}`
}

// 格式化月份 yyyy/MM
export function formatMonth(date: Date | number) {
  if (typeof date === 'number') {
    date = newDate(date)
  }
  return `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1)].join('/')}`
}

// 格式化时间 yyyy/MM/dd | HH:mm
export function formatDateTime(date: Date | number) {
  if (typeof date === 'number') {
    date = newDate(date)
  }
  if (!date) return '-'
  return `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('/')} ${[
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
  ].join(':')}`
}

// 格式化时间 yyyy/MM/dd HH:mm:ss
export function formatDateTimeFull(date: Date | number) {
  if (typeof date === 'number') {
    date = newDate(date)
  }
  if (!date) return '-'
  return `${[date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('/')} ${[
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    padTo2Digits(date.getSeconds()),
  ].join(':')}`
}

// 格式化时间 当日显示HH:mm 否则显示yyyy/MM/dd HH:mm:ss
export function formatIsTodayDateTime(date: Date | number) {
  if (typeof date === 'number') {
    date = newDate(date)
  }
  if (newDate(date).toDateString() === newDate().toDateString()) {
    return `${[padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')}`
  }
  return formatDateTime(date)
}

/**
 * 格式化时间
 * maxUint为H时 最大显示到小时 格式HH?:mm:ss
 * maxUnit为D时 最大显示到日期 格式dd D? HH:mm:ss
 * forceHour显示HH:mm:ss 否则显示HH?:mm:ss
 */
export function formatDuration(dur: number, forceFull?: boolean)
export function formatDuration(dur: number, maxUnit: 'H' | 'D', forceFull?: boolean)
export function formatDuration(dur: number, maxUnit: any = 'H', forceFull?: boolean) {
  if (typeof maxUnit === 'boolean') {
    forceFull = maxUnit
    maxUnit = 'H'
  } else if (!maxUnit) {
    forceFull = false
    maxUnit = 'H'
  }
  if (maxUnit === 'H') {
    const hour = forceFull ? padTo2Digits(Math.floor(dur / 1000 / 60 / 60)) : Math.floor(dur / 1000 / 60 / 60)
    return `${[hour, padTo2Digits(Math.floor((dur / 1000 / 60) % 60)), padTo2Digits(Math.floor((dur / 1000) % 60))]
      .filter(Boolean)
      .join(':')}`
  } else if (maxUnit === 'D') {
    const day = forceFull
      ? padTo2Digits(Math.floor(dur / 1000 / 60 / 60 / 24), 1)
      : Math.floor(dur / 1000 / 60 / 60 / 24)
    const hour = padTo2Digits(Math.floor(dur / 1000 / 60 / 60) % 24)
    return `${!!day ? day + ' D ' : ''}${[
      hour,
      padTo2Digits(Math.floor((dur / 1000 / 60) % 60)),
      padTo2Digits(Math.floor((dur / 1000) % 60)),
    ]
      .filter(Boolean)
      .join(':')}`
  }
  return
}

// 格式化时间 forceHour显示HH:mm:ss 否则显示HH?:mm:ss
// export function formatDuration(date: number, forceHour?: boolean) {
//   const hour = forceHour ? padTo2Digits(Math.floor(date / 1000 / 60 / 60)) : Math.floor(date / 1000 / 60 / 60)
//   return `${[hour, padTo2Digits(Math.floor((date / 1000 / 60) % 60)), padTo2Digits(Math.floor((date / 1000) % 60))]
//     .filter(Boolean)
//     .join(':')}`
// }

/* 获取当月最大日期 */
export function getMonthDayCnt(date: Date) {
  date.setMonth(date.getMonth() + 1)
  date.setDate(0)
  return date.getDate()
}

/** 倒计时 当日显示 HH H mm Min 否则显示N day*/
export const formatCountdownTime = (time: any, now?: number) => {
  let str = ''
  const nowTime = now ? newDate(now) : newDate() //获取当前时间
  const setTime = newDate(Number(time)) //设置结束时间

  const remianAllSeconds = Math.floor((setTime.getTime() - nowTime.getTime()) / 1000) //剩余总秒数
  if (remianAllSeconds < 0) {
    str = ' '
  } else {
    const remainDays = Math.floor(remianAllSeconds / (60 * 60 * 24)) //剩余天数
    const remainHours = Math.floor((remianAllSeconds / (60 * 60)) % 24) //剩余小时数
    const remainMinutes = Math.floor((remianAllSeconds / 60) % 60) //剩余分钟数
    if (remainDays > 1) {
      str = `${remainDays} day `
    } else {
      str = `${remainHours} H ${remainMinutes} Min`
    }
  }

  return str
}
