import validate from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_brz7t3lkqaifvohxu34hw6tm2y/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/middleware/01.auth.global.ts";
import _02_45title_45global from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/middleware/02.title.global.ts";
import _03_45dialog_45global from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/middleware/03.dialog.global.ts";
import _04_45modal_45global from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/middleware/04.modal.global.ts";
import _05_45popup_45global from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/packages/layers/base/middleware/05.popup.global.ts";
import manifest_45route_45rule from "/data/jenkins/workspace/LIVE_STREAM_V2_STAGE/zhp-web-k1/zhp-web/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.10.1_encoding@0.1.13_eslint@8.57.0_ioredis@5_brz7t3lkqaifvohxu34hw6tm2y/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45title_45global,
  _03_45dialog_45global,
  _04_45modal_45global,
  _05_45popup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}