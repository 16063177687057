import { LazyAuthModal, LazyDepositModal, LazySiteMsgModal } from '#components';

const modalRoutes: { modal: string; type?: string; component: any; auth?: boolean }[] = [
  { modal: 'ath', component: LazyAuthModal },
  { modal: 'cashier', type: 'fiat_deposit', component: LazyDepositModal, auth: true },
  { modal: 'global', type: 'notify', component: LazySiteMsgModal, auth: true },
]
const isNeedAuth = (query: any): any => {
  return modalRoutes.find((item) => item.modal === query.modal && (!item.type || item.type === query.type))?.auth
}
const resolveModalComp = (query: any): any => {
  const route = modalRoutes.find((item) => item.modal === query.modal && (!item.type || item.type === query.type))
  return route?.component
}

export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return

  const { setRouteQuery, setRouteParams, isReady } = useGlobalStore()
  const { isLogin } = useUserStore()
  const router = useRouter()

  setRouteQuery({ ...to.query })
  setRouteParams({ ...to.params })

  Log.debug('modal middleware', isLogin, isReady)
  
  const { matched } = router.currentRoute.value
  
  if (to.query.modal && (to.query.modal !== from.query.modal || !matched[0].components?.modal)) {
    if (isNeedAuth(to.query) && !getUserRegChn()) {
      return navigateTo({ query: { modal: 'ath', tab: 'signin' }, path: to.path })
    }
    const modalComp = resolveModalComp(to.query)
    nextTick(() => (matched[0].components!.modal = modalComp))
  } else if (!to.query.modal) {
    // remove modal component
    // 这里好像不能直接删除弹窗，不然在路由前进以后，后退回来，无法正常展示弹窗，在default.vue中36行添加判断来移除弹窗组件
    // matched[0] && matched[0].components && delete matched[0].components.modal
  }
})
