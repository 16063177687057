export default defineNuxtPlugin({
  name: 'ROUTER',
  async setup(nuxtApp) {
    const localePath = useLocalePath()
    const router = useRouter()
    const back = router.back
    router.back = () => {
      // 手动后退防止出站
      if (history.length <= 1 || history.state?.position === 1) {
        router.push(localePath('/'))
      } else {
        back()
      }
    }
    if (process.client) {
      window.addEventListener(
        'popstate',
        (e) => {
          // console.log('......1', history.state.type, history.state.current, history.length, JSON.stringify(history.state))
          if (history.state?.type === 'dialog' && !history.state?.current) {
            // 清理dialog写入的空状态
            history.go(-1)
          } else if (!history.state?.back && !history.state?.replaced) {
            // 阻止出站
            router.push(localePath('/'))
            return false
          }

          // console.log('......2', history.state.type, history.length, JSON.stringify(history.state))
        },
        true
      )
    }
  },
})
