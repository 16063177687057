export const useInviteStore = defineStore('inviteStore', () => {
  // 获得代理彩金信息
  const incomeInfo: Ref<IProxyBonusInfoResp> = ref()
  async function initInviteIncomeInfo() {
    const { userCurrencyId } = useUserStore()
    const response = await request(verticalProxyBonusInfo({ currencyId: userCurrencyId }))
    incomeInfo.value = response
  }

  async function formatInviteLink(code: string) {
    return incomeInfo.value?.inviteLink?.replace('{code}', code || '') || ''
  }

  // 获得里程碑信息
  const hasShowMilestone: Ref<boolean> = ref(false)
  const milestoneConf: Ref<IProxyMilestoneConfInfoResp> = ref()
  async function initInvitMilestoneConf() {
    const { userCurrencyId } = useUserStore()
    const response = await request(verticalProxyMilestoneConfig({ currencyId: userCurrencyId }))
    milestoneConf.value = response
  }
  //获得团队信息
  const teamInfo: Ref<IProxyTeamInfoResp> = ref({})
  async function initInviteTeamInfo() {
    const { userCurrencyId } = useUserStore()
    const response = await request(verticalProxyTeamInfo({ currencyId: userCurrencyId }))
    teamInfo.value = response
  }

  // 获得代理规则列表
  const ruleList: Ref<IVProxyRuleInfo[]> = ref([])
  async function initInvitRuleList() {
    const { $device } = useNuxtApp()
    const response = await request(
      verticalProxyRuleList({
        platform: $device.isMobile ? PlatformType_const.PlatformTypeMobile : PlatformType_const.PlatformTypeWeb,
      })
    )
    ruleList.value = response.proxyRules
  }

  // 获得代理钱包列表
  const walletList: Ref<ProxyBonusWalletListResp.IwalletInfo[]> = ref([])
  const walletMap = computed(() => walletList.value.reduce((prev, item) => (prev[item.currencyId] = item.bonusBalance,prev), {}))
  async function initInviteWalletList() {
    const response = await request(verticalProxyBonusWalletList({}))
    walletList.value = response?.list || []
  }

  return {
    incomeInfo,
    initInviteIncomeInfo,
    formatInviteLink,
    hasShowMilestone,
    milestoneConf,
    initInvitMilestoneConf,
    teamInfo,
    initInviteTeamInfo,
    ruleList,
    initInvitRuleList,
    walletList,
    walletMap,
    initInviteWalletList,
  }
})

// 代理用户可结算余额
// export const useAgentSettlementBalance = defineStore('agentSettlementBalance', () => {
//   const balanceList = ref<Array<IVSettlementBalance>>()

//   async function fetchBalanceList() {
//     const response = (await request(verticalProxySettlementBalance({}))) || {}
//     balanceList.value = response?.list || []
//   }

//   async function getCurrentBalance() {
//     const { userCurrencyId } = useUserStore()
//     if (!balanceList.value || balanceList.value.length === 0) {
//       await fetchBalanceList()
//     }
//     return computed(() => balanceList.value?.find(({ currencyId }) => Number(currencyId) === userCurrencyId) || {})
//   }

//   async function getBalanceList() {
//     if (!balanceList.value || balanceList.value.length === 0) {
//       await fetchBalanceList()
//     }
//     return balanceList
//   }

//   async function getBalanceMap() {
//     if (!balanceList.value || balanceList.value.length === 0) {
//       await fetchBalanceList()
//     }

//     return computed(() => {
//       const balanceMap: Record<number, string> = {}
//       balanceList.value.forEach(({ currencyId, balance }) => (balanceMap[Number(currencyId)] = balance))
//       return balanceMap
//     })
//   }

//   return { fetchBalanceList, getBalanceList, getBalanceMap, getCurrentBalance }
// })
