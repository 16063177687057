export default defineNuxtPlugin({
  name: 'REM',
  parallel: true,
  async setup(nuxtApp) {
    const { $device } = useNuxtApp()
    // 基准大小
    const baseSize = 16

    const setRem = () =>
      (document.documentElement.style.fontSize = baseSize * ($device.isMobile ? innerWidth / 375 : 1) + 'px')
    const setRemThrottle = throttle(setRem, 300, true)

    const setViewHeight = () => {
      document.documentElement.style.setProperty('--vh', window.innerHeight / 100 + 'px')
    }
    const setViewHeightThrottle = throttle(setViewHeight, 1000 / 60)

    onNuxtReady(() => {
      setRem()
      setViewHeight()
      document.addEventListener('DOMContentLoaded', () => {
        setRem()
        setViewHeight()
      })
      window.addEventListener('resize', () => {
        setRemThrottle()
        setViewHeightThrottle()
      })
    })
  },
})
