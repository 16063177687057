import {
  LazyActDailySignin,
  LazyActFPCollection,
  LazyActSpinWheel,
  LazyActWMSubscription,
  LazyLiveSupport,
  LazyPromoCode,
  LazyVerifyEmail,
  LazyVerifyPhone,
} from '#components'

export function isValidateJumpLocation(option?: IJumpLocationInfo | null) {
  if (!option) return false
  if (!option.jumpCode && !option.jumpUrl) return false
  return true
}

function _jumpToCategoryPage(catgId: number) {
  const { GAME_TYPE_KEY_MAP } = useGameType()
  const localePath = useLocalePath()
  const { $device } = useNuxtApp()
  const router = useRouter()
  if ($device.isMobile) {
    router.push({ path: localePath(`/games/category/${GAME_TYPE_KEY_MAP[catgId]}`) })
  } else {
    router.push({ path: localePath(`/games/catg/${catgId}`) })
  }
}
function _jumpToPlatformPage(platformId?: string) {
  const localePath = useLocalePath()
  const { $device } = useNuxtApp()
  const router = useRouter()
  if ($device.isMobile) {
    router.push({ path: localePath(`/games/platform/${platformId || ''}`) })
  } else {
    router.push({ path: localePath(`/provider/${platformId}`) })
  }
}

export function getDownloadPagePath() {
  const { getInviteCode } = useGlobalStore()
  const localePath = useLocalePath()
  const code = getInviteCode()
  const q: any = { chn: getChannel(1) }
  if (code) q.code = getInviteCode()
  return localePath({ path: '/download', query: q })
}

interface jumpCbFunc {
  (option?: IJumpLocationInfo | null, query?: Record<any, any>): void
}

const _jumpLocationMapper = (): Record<ValueOf<typeof JumpLocationCode_const>, jumpCbFunc> => {
  const { getInviteCode } = useGlobalStore()

  const router = useRouter()
  const localePath = useLocalePath()
  const { isLogin, userInfo } = storeToRefs(useUserStore())
  const map: Record<ValueOf<typeof JumpLocationCode_const>, jumpCbFunc> = {
    [JumpLocationCode_const.JumpNone]: () => {}, // 未定义
    [JumpLocationCode_const.JumpRecharge]: (option, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      openModalRoute({ modal: 'cashier', type: 'fiat_deposit' }, query)
    }, // 充值存款
    [JumpLocationCode_const.JumpSystemShare]: () => shareInviteCode(), // 系统分享
    [JumpLocationCode_const.JumpActivityRoulette]: () => openDialog(LazyActSpinWheel), // 转盘活动
    [JumpLocationCode_const.JumpActivityInvite]: (option, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath('/invite'), query })
    }, // 邀请拉新
    [JumpLocationCode_const.JumpActivitySignIn]: () => openDialog(LazyActDailySignin), // 签到活动
    [JumpLocationCode_const.JumpDailyTask]: () => {}, // deprecated 每日任务
    [JumpLocationCode_const.JumpGameTypeSlotGames]: () => _jumpToCategoryPage(GameType_const.GameTypeSlotGames), // 电子游戏列表
    [JumpLocationCode_const.JumpGameTypeSports]: () => _jumpToCategoryPage(GameType_const.GameTypeSports), // 体育游戏列表
    [JumpLocationCode_const.JumpGameTypeLiveCasino]: () => _jumpToCategoryPage(GameType_const.GameTypeLiveCasino), // 真人视讯游戏列表
    [JumpLocationCode_const.JumpGameTypeFishing]: () => _jumpToCategoryPage(GameType_const.GameTypeFishing), // 捕鱼游戏列表
    [JumpLocationCode_const.JumpGameTypeBoardGame]: () => _jumpToCategoryPage(GameType_const.GameTypeBoardGame), // 棋牌游戏列表
    [JumpLocationCode_const.JumpGameTypeVirtualSports]: () => _jumpToCategoryPage(GameType_const.GameTypeVirtualSports), // 虚拟体育游戏列表
    [JumpLocationCode_const.JumpCasinoIndex]: (_, query) => router.push({ path: localePath(`/`), query }), // 综合盘首页
    [JumpLocationCode_const.JumpLiveIndex]: () => {}, // deprecated 直播首页
    [JumpLocationCode_const.JumpMovieIndex]: () => {}, //deprecated  点播首页
    [JumpLocationCode_const.JumpBindingPhone]: () => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      openDialog(LazyVerifyPhone, {
        phone: userInfo.value?.phone,
        countryCode: userInfo.value?.countryCode,
      })
    }, // 绑定手机号页
    [JumpLocationCode_const.JumpUrl]: (option) => {
      option?.jumpUrl && (window.location.href = option.jumpUrl)
    }, // 跳转指定url
    [JumpLocationCode_const.JumpGameDetail]: (option, query) => {
      router.push({ path: localePath(`/game/view/${option?.gameId}`), query })
    }, // 跳转游戏详情
    [JumpLocationCode_const.JumpVIPReward]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath(`/user/vipclub`), query })
    }, // VIP奖励
    [JumpLocationCode_const.JumpVIPCentre]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath(`/user/vipclub`), query })
    }, // VIP中心
    [JumpLocationCode_const.JumpCustomerList]: () => openDialog(LazyLiveSupport), // 客服列表
    [JumpLocationCode_const.JumpGeneralMall]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath(`/userzone/store`), query })
    }, // 普通商城
    [JumpLocationCode_const.JumpPointsMall]: () => {}, //deprecated 积分商城
    [JumpLocationCode_const.JumpPiggyBank]: () => {}, //deprecated 跳转存钱罐
    [JumpLocationCode_const.JumpActivityDetail]: (option) => {
      router.push({ path: localePath(`/activity/${option?.activityId}`) })
    }, // 跳转指定活动页
    [JumpLocationCode_const.JumpProxyVertical]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath('/invite'), query })
    }, // 跳转代理页面
    [JumpLocationCode_const.JumpGamePlatform]: (option) => _jumpToPlatformPage(option?.gamePlatformKey), // 跳转到游戏厂商页
    [JumpLocationCode_const.JumpBindingEmail]: () => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      openDialog(LazyVerifyEmail, { email: userInfo.value?.email })
    }, // 绑定邮箱页
    [JumpLocationCode_const.JumpHinge]: () => {
      openDialog(LazyActFPCollection)
    }, //打脸图合页
    [JumpLocationCode_const.JumpMilestone]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath('/invite/income/goal'), query })
    }, // 里程碑頁面
    [JumpLocationCode_const.JumpRechargeCard]: () => openDialog(LazyActWMSubscription), // 周卡月卡
    [JumpLocationCode_const.JumpExchangeCode]: () => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      openDialog(LazyPromoCode)
    }, // 兑换码
    [JumpLocationCode_const.JumpAppDownload]: () => {
      router.push(getDownloadPagePath())
    }, // App下载页
    [JumpLocationCode_const.JumpProxyRule]: (_, query) => {
      if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
      router.push({ path: localePath('/invite/rule'), query })
    }, // 代理规则页
    [JumpLocationCode_const.JumpRegister]: () => {
      openModalRoute({ modal: 'ath', tab: 'register' })
    }, // 注册页面
    [JumpLocationCode_const.JumpShowOnly]: () => {}, // 仅展示无需跳转
    [JumpLocationCode_const.jumpOpenPopup]: async (option) => {
      const { openPopup } = useFacePunch()
      openPopup(option?.popupId)
    }, // 打开打脸弹窗
    [JumpLocationCode_const.JumpExternalUrl]: (option) => {
      option?.jumpUrl && openWindow(option.jumpUrl)
    }, // 跳转外部url
  }
  return map
}

export function jumpInfoToJumpLocationInfo(option?: Iactivity_popup): IJumpLocationInfo {
  if (!option || !option.jumpLocation) return {}
  return {
    jumpCode: option.jumpLocation,
    jumpUrl: option.jumpParam,
    gameId: Number(option.jumpParam),
    activityId: Number(option.jumpParam),
    popupId: Number(option.jumpParam),
  }
}

export function openByJumpCode(option?: IJumpLocationInfo, query?: Record<any, any>): void {
  if (!option) return
  const mapper = _jumpLocationMapper()
  const func = mapper[option.jumpCode]
  return func && func(option, query)
  // const { getInviteCode } = useGlobalStore()

  // const { isLogin, userInfo } = storeToRefs(useUserStore())
  // const router = useRouter()
  // const localePath = useLocalePath()
  // const { jumpCode, jumpUrl, gameId, activityId } = option
  // switch (jumpCode) {
  //   case JumpLocationCode_const.JumpNone: //未定义
  //   case JumpLocationCode_const.JumpUrl:
  //     jumpUrl && window.open(jumpUrl)
  //     return
  //   case JumpLocationCode_const.JumpActivityDetail:
  //     router.push({ path: localePath(`/activity/${activityId}`) })
  //     return
  //   case JumpLocationCode_const.JumpCustomerList:
  //     openDialog(LazyLiveSupport)
  //     return
  //   case JumpLocationCode_const.JumpGameDetail: // 游戏详情
  //     router.push({ path: localePath(`/game/view/${gameId}`), query })
  //     return
  //   case JumpLocationCode_const.JumpGameTypeSlotGames: // 电子游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeSlotGames)
  //   case JumpLocationCode_const.JumpGameTypeSports: // 体育游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeSports)
  //   case JumpLocationCode_const.JumpGameTypeLiveCasino: // 真人视讯游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeLiveCasino)
  //   case JumpLocationCode_const.JumpGameTypeFishing: // 捕鱼游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeFishing)
  //   case JumpLocationCode_const.JumpGameTypeBoardGame: // 棋牌游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeBoardGame)
  //   case JumpLocationCode_const.JumpGameTypeVirtualSports: // 虚拟体育游戏列表
  //     return _jumpToCategoryPage(GameType_const.GameTypeSports)
  //   case JumpLocationCode_const.JumpCasinoIndex: // 综合盘首页
  //     router.push({ path: localePath(`/`), query })
  //     return
  //   case JumpLocationCode_const.JumpSystemShare: // 系统分享
  //     shareInviteCode()
  //     return
  //   case JumpLocationCode_const.JumpVIPReward:
  //   case JumpLocationCode_const.JumpVIPCentre:
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     router.push({ path: localePath(`/user/vipclub`), query })
  //     return
  //   case JumpLocationCode_const.JumpGeneralMall:
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     router.push({ path: localePath(`/userzone/store`), query })
  //     return
  //   case JumpLocationCode_const.JumpBindingPhone: // 绑定手机号页
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     openDialog(LazyVerifyPhone, {
  //       phone: userInfo.value?.phone,
  //       countryCode: userInfo.value?.countryCode,
  //     })
  //     return
  //   case JumpLocationCode_const.JumpBindingEmail: // 绑定邮箱
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     openDialog(LazyVerifyEmail, { email: userInfo.value?.email })
  //     return
  //   case JumpLocationCode_const.JumpRecharge: // 充值存款
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     openModalRoute({ modal: 'cashier', type: 'fiat_deposit' }, query)
  //     return
  //   case JumpLocationCode_const.JumpActivityRoulette: // 转盘
  //     openDialog(LazyActSpinWheel)
  //     return
  //   case JumpLocationCode_const.JumpActivitySignIn: // 签到
  //     openDialog(LazyActDailySignin)
  //     return
  //   case JumpLocationCode_const.JumpHinge: // 活动合集
  //     openDialog(LazyActFPCollection)
  //     return
  //   case JumpLocationCode_const.JumpRechargeCard: // 周卡月卡
  //     openDialog(LazyActWMSubscription)
  //     return
  //   case JumpLocationCode_const.JumpExchangeCode: // 兑换码
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     openDialog(LazyPromoCode)
  //     return
  //   case JumpLocationCode_const.JumpAppDownload: // 下载
  //     const code = getInviteCode()
  //     const q: any = { chn: getChannel(1) }
  //     if (code) q.code = getInviteCode()
  //     router.push({ path: localePath('/download'), query: q })
  //     return
  //   // ===========裂变==============
  //   case JumpLocationCode_const.JumpActivityInvite: // 邀请拉新
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     router.push({ path: localePath('/invite'), query })
  //     return
  //   case JumpLocationCode_const.JumpMilestone: // 里程碑
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     router.push({ path: localePath('/invite/income/goal'), query })
  //     return
  //   case JumpLocationCode_const.JumpProxyRule: // 代理规则
  //     if (!isLogin.value) return openModalRoute({ modal: 'ath', tab: 'signin' })
  //     router.push({ path: localePath('/invite/rule'), query })
  //     return
  //   default:
  // }
}

export function getFullLocalePath(path?: string): string {
  const router = useRouter()
  const localePath = useLocalePath()
  const fullPath = router.resolve(localePath(path || '/')).fullPath
  const baseURL: string = import.meta.env.VITE_APP_BASE_URL || '/'

  if (baseURL.endsWith('/') && fullPath.startsWith('/')) {
    return `${baseURL}${fullPath.substring(1)}`
  }

  if (!baseURL.endsWith('/') && !fullPath.startsWith('/')) {
    return `${baseURL}/${fullPath}`
  }

  return `${baseURL}${fullPath}`
}

export async function jumRootUrl(isReloadData?: boolean) {
  // window.location.href = getFullLocalePath()
  const router = useRouter()
  const localePath = useLocalePath()
  const { isReady } = storeToRefs(useGlobalStore())
  const { lockFacePopup } = useFacePunch()

  lockFacePopup()

  isReady.value = false

  await router.push({ path: localePath('/') })

  if (isReloadData) {
    const nuxtApp = useNuxtApp()
    nuxtApp.runWithContext(() => {
      const { initUserToken } = useUserData()
      nextTick(async () => {
        try {
          await useDelayLoading(() => initUserToken())
          // .then(() => nextTick(loadUserData)))
          emitter.emit('reload')
        } finally {
          isReady.value = true
        }
      })
    })
  } else {
    isReady.value = true
  }
}
