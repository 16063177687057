const SOCKET_URL = socketurl()
// ws状态
export const WS_STATUS_SUCCESS = 0 // 连接成功
export const WS_STATUS_NO_LOGIN = 401 // 用户未登录
export const WS_STATUS_KICKED = 409 // 当前账号已在其他客户端登录
export const WS_STATUS_CLOSED = 400 // 连接已关闭
export const WS_STATUS_SERVER_ERROR = 503 //无法与服务端建立连接
export const WS_STATUS_KILL = 504 // 心跳检测失败次数超过3次

// 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
const camel2snake = (s: string) => s.replace(/([A-Z])/g, '_$1').toLowerCase()

// JSON对象的key值转换为下划线格式
const json2snake = (obj: any) => {
  if (obj instanceof Array) {
    obj.forEach(json2snake)
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      const newKey = camel2snake(key)
      if (newKey !== key) {
        obj[newKey] = obj[key]
        delete obj[key]
      }
      json2snake(obj[newKey])
    })
  }
  return obj
}

// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
const snake2camel = (s: string) => s.replace(/_(\w)/g, (_: any, letter: string) => letter.toUpperCase())

// JSON对象的key值转换为驼峰式
const json2camel = (obj: any) => {
  if (obj instanceof Array) {
    obj.forEach(json2camel)
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      const newKey = snake2camel(key)
      if (newKey !== key) {
        obj[newKey] = obj[key]
        delete obj[key]
      }
      json2camel(obj[newKey])
    })
  }
  return obj
}

function bytesToString(uintArray) {
  var encodedString = String.fromCharCode.apply(null, uintArray),
    decodedString = decodeURIComponent(escape(encodedString))
  return decodedString
}

function stringToBytes(s: string): Uint8Array {
  let arr = new Uint8Array(s.length)
  for (let i = 0, l = s.length; i < l; i++) {
    arr[i] = s.charCodeAt(i)
  }
  return arr
}

const noop = () => {}
interface CallbackOptions {
  onError: Function
  onOpen: Function
  onBeforeMsg: Function
  onAfterMsg: Function
  onBeforeSend: Function
  onAfterSend: Function
  onBeforeClose: Function
  onAfterClose: Function
}

/**
 * websocket监听
 */
class WS {
  url?: any
  websocket?: WebSocket | null
  status: number | null
  ecnt: number
  heartbeat: { timer: any; count: number }
  options: CallbackOptions
  userId: any
  token: any
  events: Partial<Record<NotifyCode, Function[]>> = {}
  isRecLocked: boolean
  authorized = false
  queue: { cmd: MsgCmdCode; data: any }[] = []

  constructor(options: Partial<CallbackOptions> = {}) {
    this.websocket = null
    this.status = null
    this.ecnt = 0
    this.isRecLocked = false
    this.heartbeat = { timer: null, count: 0 }

    this.options = {
      onError: options.onError || noop,
      onOpen: options.onOpen || noop,
      onBeforeMsg: options.onBeforeMsg || noop,
      onAfterMsg: options.onAfterMsg || noop,
      onBeforeSend: options.onBeforeSend || noop,
      onAfterSend: options.onAfterSend || noop,
      onBeforeClose: options.onBeforeClose || noop,
      onAfterClose: options.onAfterClose || noop,
    }
  }

  on(code: NotifyCode, cb: Function) {
    if (!this.events[code]) {
      this.events[code] = []
    }
    this.events[code]!.push(cb)
  }

  off(code: NotifyCode, cb: Function) {
    const cbs = this.events[code]
    if (!cbs) return
    const idx = cbs.findIndex((item) => item === cb)
    if (idx > -1) cbs.splice(idx, 1)
  }

  emit(code: NotifyCode, event: any) {
    Log.debug('on receive message:' + code)
    const cbs = this.events[code]
    if (!cbs) return
    for (const cb of cbs) {
      try {
        cb(event)
      } catch (e) {
        Log.error(e)
      }
    }
  }

  init(userId: any, token: any) {
    this.userId = userId
    this.token = token
    this.url = this._formatWsUrl(SOCKET_URL)
    this.connect()
  }

  reconnect() {
    if (this.isRecLocked) return
    this.isRecLocked = true
    const delay = this.ecnt++ * 5 * 1000
    Log.debug(`reconnect after ${delay / 1000}s`)
    //没连接上会一直重连，设置延迟避免请求过多
    setTimeout(() => (this.connect(), (this.isRecLocked = false)), delay)
  }

  connect() {
    if (!this.url) {
      Log.error('ws: url cannot be null')
      return
    }
    if (!this.userId || !this.token) {
      Log.error('ws: no auth user.')
      return
    }

    try {
      if ('WebSocket' in window) {
        this.websocket = new WebSocket(this.url)
        this.websocket.binaryType = 'arraybuffer'
      } else {
        // Notification({ title: '提示', message: '该浏览器不支持WebSocket，请更新到最新版本', duration: 0 })
        Log.error('Error: WebSocket is not supported by this browser.')
        return
      }
      this.websocket.onerror = (event) => {
        this.status = WS_STATUS_SERVER_ERROR
        this.options.onError(event, this)
        Log.error(`websocket status is abnormal: ${this.status}`)
      }

      this.websocket.onopen = (event: any) => {
        this.heartbeat.count = 0
        this.status = WS_STATUS_SUCCESS
        this.initAuth()
        Log.debug('websocket is open')
        this.options.onOpen(event, this)
      }

      this.websocket.onmessage = (event: MessageEvent) => {
        const data = JSON.parse(bytesToString(new Uint8Array(event.data)))
        this.options.onBeforeMsg(data, this)
        this.onmessage(data)
        this.options.onAfterMsg(data, this)
      }

      this.websocket.onclose = (event: any) => {
        this.options.onBeforeClose(event, this)
        this.onclose(event)
        this.options.onAfterClose(event, this)
      }
    } catch (e) {
      this.status = WS_STATUS_SERVER_ERROR
      this.options.onError(event, this)
    }
  }

  // 收到消息处理
  private onmessage(event: Imsg_push_common_message) {
    const { cmd, data } = event
    if (cmd === MsgCmdCode_const.Msg_Cmd_Heartbeat) {
      // 处理心跳消息
      this.heartbeat.count = 0
      this.status = WS_STATUS_SUCCESS
    } else if (cmd === MsgCmdCode_const.Msg_Cmd_Auth) {
      const response = json2camel(JSON.parse(atob(data as any)))
      if (response.authResult) {
        this.authorized = true
        this.ecnt = 0
        this.initHeartbeat()
        this.dequeue()
      } else {
        this.authorized = false
        this.userId = null
        this.token = null
        this.close()
      }
    } else if (cmd === MsgCmdCode_const.Msg_Cmd_Notify) {
      const response = json2camel(JSON.parse(atob(data as any)))
      this.emit(response.code, json2camel(JSON.parse(atob(response.data))))
    }
  }

  private onclose(event: any) {
    this.status = WS_STATUS_CLOSED
    // if (event.code !== 1000) {
    //   console.error(event.code, event.reason);
    // }
    Log.debug('websocket closed')
    this.reconnect()
  }

  close() {
    if (this.websocket) {
      clearInterval(this.heartbeat.timer)
      this.websocket.close()
    }
  }

  send(cmd: MsgCmdCode, data: any) {
    if (this.status !== WS_STATUS_SUCCESS || !this.authorized) {
      this.queue.push({ cmd, data })
      return
    }
    this.options.onBeforeSend(cmd, data)
    this._send(cmd, data)
    this.options.onAfterSend(cmd, data)
  }

  dequeue() {
    while (this.queue && this.queue.length) {
      const data = this.queue.shift()
      this.send(data.cmd, data.data)
    }
  }

  private initAuth() {
    this._send(MsgCmdCode_const.Msg_Cmd_Auth, {
      userId: this.userId,
      token: this.token,
    } as Imsg_push_auth_req)
  }

  private initHeartbeat() {
    this.heartbeat.timer = setInterval(() => {
      if ([WS_STATUS_SERVER_ERROR, WS_STATUS_CLOSED, WS_STATUS_KICKED].includes(this.status || 0)) {
        clearInterval(this.heartbeat.timer)
        return
      }
      if (this.heartbeat.count > 3) {
        this.status = WS_STATUS_KILL
        this.close()
        return
      }
      this.heartbeat.count++
      this._send(MsgCmdCode_const.Msg_Cmd_Heartbeat, { timestamp: Date.now() } as Imsg_push_heartbeat_req)
    }, 10000)
  }

  // 格式化websocket url
  private _formatWsUrl(url: string) {
    const protocol = window.location.href.indexOf('https') > -1 ? 'wss' : 'ws'
    const host = window.location.host
    if (/^\/\//.test(url)) {
      return protocol + url
    } else if (/^\/\w+/.test(url)) {
      return protocol + '://' + host + url
    }
    return url + '?format=json'
  }

  private _send(cmd: MsgCmdCode, data: any) {
    const reqData = { cmd, data: btoa(JSON.stringify(json2snake(data))) }
    this.websocket?.send(stringToBytes(JSON.stringify(reqData)))
  }
}

export default defineNuxtPlugin({
  name: 'WS',
  async setup(nuxtApp) {
    if (process.server) return

    const ws = new WS()

    const userStore = useUserStore()
    const { activityRewardStatusMap } = storeToRefs(useActRewardStore())
    const { initSiteMsg } = useSiteMsgStore()
    const { shiftLatestBets } = useCasinoStore()
    const { getUserToken, updateUserBalance } = userStore
    const { isLogin, userInfo, userCurrencyId, userCurrencyCode, userCurrencyBalance } = storeToRefs(userStore)
    watch(
      () => userInfo.value?.userId,
      (val) => {
        if (!userInfo.value?.userId) return
        ws.close()
        ws.init(val, getUserToken())
      }
    )

    // 站内信
    ws.on(NotifyCode_const.N_UserTipStatusNotify, (data: Inotify_user_tip_status_notify) => {
      if (data.code === notify_user_tip_status_notify_tip_code_const.TIP_UserMsgCenter) {
        initSiteMsg()
      }
    })

    // 活动小红点
    ws.on(NotifyCode_const.N_ActivityStatusTip, (data: Inotify_activity_status_tip) => {
      for (const t of data.activityTip) {
        // TODO 通知类型
        const idx: activity_system_type = Number(t.activitySystemType) || 0
        activityRewardStatusMap.value[idx] = Boolean(t.status)
      }
    })

    // @deprecated 新道具
    ws.on(NotifyCode_const.N_PropUserGetNewProp, () => {
      const { setHasNewProp } = useUserStore()
      setHasNewProp(true)
    })

    // 余额变动
    ws.on(NotifyCode_const.N_WalletCurrencyChange, (data: Inotify_wallet_currency_change_notify) => {
      Log.debug('wallet_currency_change', data)
      updateUserBalance(data.currencyId, data.balance)
      if (data.currencyId === userCurrencyId.value) {
        userCurrencyBalance.value = data.balance
      }
    })
    // af回调
    ws.on(NotifyCode_const.N_WalletAppFlayerEvent, (data: Inotify_wallet_app_flayer_event_notify) => {
      const { eventId, type, eventValue, eventValue2 } = data
      const value = eventValue
      const currency = eventValue2
      if (type === notify_wallet_app_flayer_event_notify_event_type_const.event_recharge_first) {
        EventTrack('deposit_first_success', { event_id: eventId, value, currency })
        request(walletAppFlayerEventHandled({ eventId }))
      } else if (type === notify_wallet_app_flayer_event_notify_event_type_const.event_recharge_second) {
        EventTrack('deposit_success', { event_id: eventId, value, currency })
        request(walletAppFlayerEventHandled({ eventId }))
      } else if (type === notify_wallet_app_flayer_event_notify_event_type_const.event_withdraw) {
        EventTrack('withdrawal_success', { event_id: eventId, value, currency })
        request(walletAppFlayerEventHandled({ eventId }))
      }
    })

    // 首充活动
    ws.on(NotifyCode_const.N_ActivityFirstRecharge, (data: Inotify_activity_first_recharge) => {
      const { isFirst, currencyId, bonus } = data
      Log.debug('activity_first_recharge', isFirst, currencyId, bonus)
      const { setRechargeBonus, rechargeBonus } = useActRechargeBonusStore()
      if (rechargeBonus) return
      setRechargeBonus({ isFirst, currencyId, bonus })
    })

    // 最近投注
    ws.on(NotifyCode_const.N_GameRankLatestBet, (data: Inotify_game_rank_latest_bet_message) => {
      Log.debug('game_rank_latest_bet', data)
      shiftLatestBets(data.list)
    })

    // 悬浮窗信息通知
    ws.on(NotifyCode_const.N_BasicPopupWindowInfo, (data: INotifyBasicPopupWindowInfoMessage) => {
      Log.debug('basic_popup_window_info', data)
      const { addFloatWindow } = useFloatWindowStore()
      data?.list?.forEach((item) => item.osPlatformType.includes('web') && addFloatWindow(item))
    })

    // 全局公告
    ws.on(NotifyCode_const.N_Bulletin, (data: Inotify_bulletin_message) => {
      Log.debug('bulletin', data)
      const { initGlobalNotification } = useGlobalNotificationStore()
      data?.osH5 && initGlobalNotification()
    })

    nuxtApp.vueApp.provide('websocket', ws)
    nuxtApp.provide('websocket', ws)
  },
})
