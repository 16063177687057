export default defineNuxtPlugin({
  name: 'TIMER',
  parallel: true,
  async setup(nuxtApp) {
    if (nuxtApp.ssrContext) {
      const timer = reactive({ now: Date.now() })
      return { provide: { timer } }
    }

    const timer = reactive({ now: Date.now() })
    setInterval(() => Object.assign(timer, { now: Date.now() }), 1000)

    return {
      provide: {
        timer,
      },
    }
  },
})
