import { useGtm } from '@gtm-support/vue-gtm'

export type TrackEvents = {
  loaded: null
  view_hall: null
  view_deposit: null
  view_withdrawal: null
  signup_success: { name: string }
  signin_success: { name: string }
  start_recharge: null
  start_withdrawal: null
  deposit_first_success: { event_id: number; value: any; currency: any }
  deposit_success: { event_id: number; value: any; currency: any }
  withdrawal_success: { event_id: number; value: any; currency: any }
}

type Tracker = 'gtm' | 'fb'

function trackGtm(ename, params) {
  const { $device } = useNuxtApp()
  const os = $device.isAndroid ? 'android' : $device.isIos ? 'ios' : 'windows'
  useGtm()?.trackEvent(Object.assign({ os }, params || {}, { event: ename }))
}

function trackFB(ename, params) {
  const { $fb } = useNuxtApp()
  const p = {}
  let eventId = null
  if (params && typeof params === 'object') {
    for (const key in params) {
      if (key === 'event_id') eventId = params.event_id
      else if (key === 'name') p['content_name'] = params.name
      else p[key] = params[key]
    }
  }
  $fb?.track(ename, p, eventId)
}

const tracker = function <Key extends keyof TrackEvents>(): Record<
  Tracker,
  Partial<Record<Key, (param: TrackEvents[Key]) => void>>
> {
  return {
    gtm: {
      loaded: (param) => trackGtm('loaded', param),
      view_hall: (param) => trackGtm('view_hall', param),
      view_deposit: (param) => trackGtm('view_deposit', param),
      view_withdrawal: (param) => trackGtm('view_withdrawal', param),
      signup_success: (param) => trackGtm('signup_success', param),
      signin_success: (param) => trackGtm('signin_success', param),
      start_recharge: (param) => trackGtm('start_recharge', param),
      start_withdrawal: (param) => trackGtm('start_withdrawal', param),
      deposit_first_success: (param) => trackGtm('add_to_cart', param),
      deposit_success: (param) => trackGtm('purchase', param),
      withdrawal_success: (param) => trackGtm('refund', param),
    } as Partial<Record<Key, (param: TrackEvents[Key]) => void>>,
    fb: {
      loaded: (param) => trackFB('h5_maincontroller', param),
      view_hall: (param) => trackFB('h5_hall_view', param),
      view_deposit: (param) => trackFB('h5_purchase_view', param),
      view_withdrawal: (param) => trackFB('view_withdrawal', param),
      signup_success: (param) => trackFB('CompleteRegistration', param),
      signin_success: (param) => trackFB('signin_success', param),
      start_recharge: (param) => trackFB('start_recharge', param),
      start_withdrawal: (param) => trackFB('start_withdrawal', param),
      deposit_first_success: (param) => trackFB('AddToCart', param),
      deposit_success: (param) => trackFB('Purchase', param),
      withdrawal_success: (param) => trackFB('h5_withdrawal', param),
    } as Partial<Record<Key, (param: TrackEvents[Key]) => void>>,
  }
}

export function EventTrack<Key extends keyof TrackEvents>(evname: Key, params?: TrackEvents[Key]) {
  const _tracker = tracker()
  emitter.emit('ev-track', { evname, params })
  for (const pt in _tracker) _tracker[pt][evname]?.call(null, params)
}
