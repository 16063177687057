import type { ComputedRef, Ref } from 'vue'

export const useGlobalStore = defineStore('globalStore', () => {
  const layout: Ref<'default' | 'social'> = ref('default')
  const isReady = ref(false)
  const isError = ref(false)
  const isLoading = ref(true)
  const errorMsg = ref('')

  const routeQuery: Ref<{ modal?: string; tab?: string; type?: string; menu?: string }> = ref({})

  const setRouteQuery = (query: { modal?: string; tab?: string; type?: string; menu?: string }) => {
    routeQuery.value = query
  }

  const routeParams: Ref<Record<string, any>> = ref({})

  const setRouteParams = (params: Record<string, any>) => {
    routeParams.value = params
  }

  const basicConfirParams: Ref<Ibasic_param_list_res | undefined> = ref()
  const propResourceList: Ref<prop_resource_info_list_res.Iprop_resource_info[]> = ref([])
  const vipLevelIcons = ref({})
  const modules: Ref<Imodule_info> = ref({})
  const loginType: Ref<Ilogin_type_info[]> = ref([])
  const registerType: Ref<Ilogin_type_info[]> = ref([])
  const socialApp: Ref<Isocial_app_info[]> = ref([])
  const authImages = ref()

  const currencyConfList: Ref<Ibasic_currency_info[]> = ref([])
  const currencyConfMap: ComputedRef<Record<number, Ibasic_currency_info>> = computed(() => {
    return currencyConfList.value.reduce(
      (prev, item) => Object.assign(prev, { [item.currencyId]: item }),
      {} as Record<number, Ibasic_currency_info>
    )
  })

  const initBasicConfigParamList = async () => {
    const response = await request(basicConfigParamList({}))
    if (!response) return
    basicConfirParams.value = response || {}
    modules.value = basicConfirParams.value?.module || {}
    loginType.value = (basicConfirParams.value?.loginType || []).filter(
      (item) => item.loginType !== login_type_const.LoginGuest && item.loginType !== login_type_const.LoginApple
    )
    propResourceList.value = basicConfirParams.value?.propResourceInfoList?.propList || []
    vipLevelIcons.value = basicConfirParams.value?.userLevelAllIcon?.levelIcon || {}
    registerType.value = basicConfirParams.value?.registerType || undefined
    socialApp.value = basicConfirParams.value?.socialApp || []
    currencyConfList.value = basicConfirParams.value?.walletCurrencyList || []

    authImages.value = (basicConfirParams.value?.loginImages || []).reduce(
      (prev, item) => {
        prev[item.imageType] = item
        return prev
      },
      {}
    )
  }

  // invite user
  const getInviteCode = () => {
    return getLocal(StorageKey.REFER_CODE)
  }

  const saveInviteCode = (code: string) => {
    setLocal(StorageKey.REFER_CODE, code)
  }

  const clearInviteCode = () => {
    removeLocal(StorageKey.REFER_CODE)
  }

  const propResourceMap: ComputedRef<Record<number, prop_resource_info_list_res.Iprop_resource_info>> = computed(() => {
    return propResourceList.value.reduce(
      (prev, item) => {
        prev[item.id] = item
        return prev
      },
      {} as Record<number, prop_resource_info_list_res.Iprop_resource_info>
    )
  })

  const customerServiceList: Ref<basic_customer_service_list_res.Icustomer_service_info[] | null> = ref(null)
  const customerServiceLink: Ref<string | null> = ref(null)
  async function initCustomerServiceList() {
    const response = await request(basicCustomerServiceList({}))
    customerServiceList.value = response.services || []
    customerServiceLink.value = response.services[0]?.link || ''
  }

  // tdk
  const tdkPathMap: Map<string, Igame_tdk_detail_resp> = reactive(new Map())
  async function initGameTdkDetail(path: string, locale: string) {
    const baseRoutePath = path?.replace(new RegExp(`^/${locale}`), '')
    const resp = await request(gameTdkDetail({ urlPath: baseRoutePath }))
    if (!resp?.urlPath) return
    tdkPathMap.set(path, resp)
  }

  // build time
  const buildTime = ref(0);
  function setBuildTime(time: number) { 
    buildTime.value = time
  }

  return {
    layout,
    isReady,
    isError,
    isLoading,
    errorMsg,
    modules,
    loginType,
    registerType,
    authImages,
    socialApp,
    basicConfirParams,
    vipLevelIcons,
    propResourceList,
    propResourceMap,
    currencyConfList,
    currencyConfMap,
    getInviteCode,
    saveInviteCode,
    clearInviteCode,
    initBasicConfigParamList,
    tdkPathMap,
    initGameTdkDetail,
    routeQuery,
    setRouteQuery,
    routeParams,
    setRouteParams,
    customerServiceList,
    customerServiceLink,
    initCustomerServiceList,
    buildTime,
    setBuildTime,
  }
})
