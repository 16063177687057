import { defineNuxtPlugin } from '#app/nuxt'
import { Button } from '@varlet/ui'
export default defineNuxtPlugin({
  name: 'varlet-plugin',
  async setup() {
    Button.setPropsDefaults({
      elevation: false,
      ripple: false,
    })
  },
})
