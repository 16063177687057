export function padStart(str: string | number, targetLength: number, padString: string) {
  str = String(str)
  targetLength = Math.floor(targetLength) || 0
  if (targetLength < str.length) return String(str)
  padString = padString ? String(padString) : ' '

  let i = 0
  let pad = ''
  const len = targetLength - str.length
  while (pad.length < len) {
    if (!padString[i]) {
      i = 0
    }
    pad += padString[i]
    i++
  }

  return pad + String(str).slice(0)
}

export function padEnd(str: string | number, targetLength: number, padString: string) {
  str = String(str)
  targetLength = Math.floor(targetLength) || 0
  if (targetLength < str.length) return String(str)
  padString = padString ? String(padString) : ' '

  let i = 0
  let pad = ''
  const len = targetLength - str.length
  while (pad.length < len) {
    if (!padString[i]) {
      i = 0
    }
    pad += padString[i]
    i++
  }

  return String(str).slice(0) + pad
}

// 用户昵称遮罩
export function maskUsername(num: number | string | null = '') {
  num = String(num || '')
  return `${num.slice(0, 2)}・・・・${num.slice(-2)}`
}

// 银行卡号遮罩
export function maskBankCardNum(num: number | string | null = '') {
  num = String(num || '')
  return `・・・・ ${num.slice(-4)}`
}

// 邮箱遮罩
export function maskEmail(num: number | string | null = '') {
  num = String(num || '')
  return `${num.slice(0, 4)}・・・・${num.slice(-4)}`
}

export function fmtTextarea(str: string) {
  str.replace(/([\r\n]){2,}/g, '$1') // 替换连续换行字符
  str.trim() // 替换开头/结尾空格
  str.replace(/"/g, '&quot;').replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/'/g, '&apos;') // 替换特殊符号
  return str
}

// 替换特殊符号
export function replaceSymbols(str: string) {
  return str
    .replace(/"/g, '&quot;')
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/'/g, '&apos;')
}

// 替换连续换行字符
export function replaceMuliEmptyLine(str: string) {
  return str.replace(/([\r\n]){2,}/g, '$1')
}

// 格式化数字单位
export function formatNumberWithUnit(number: number) {
  if (number >= 1e18) {
    return (number / 1e18).toFixed(2) + 'E'
  } else if (number >= 1e15) {
    return (number / 1e15).toFixed(2) + 'P'
  } else if (number >= 1e12) {
    return (number / 1e12).toFixed(2) + 'T'
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(2) + 'G'
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(2) + 'M'
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(2) + 'K'
  } else {
    return number.toString()
  }
}

// 判断是否json字符串
export function isJSON(str: string) {
  if (!str) return false
  try {
    let obj = JSON.parse(str)
    return typeof obj === 'object' && obj
  } catch (e) {
    return false
  }
}

// 随机字符串
export function randomStr(e?: number) {
  e = e || 32
  const t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  let a = t.length
  let n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}
