
export default defineNuxtRouteMiddleware((to, from) => {
  if (process.server) return
  
  const localPath = useLocalePath()
  const { emitEventPopup } = useFacePunch()
  
  // 进入首页
  if (to.fullPath !== from.fullPath && to.fullPath === localPath('/')) {
    // 进入大厅打脸图
    emitEventPopup(FrontEndEventType_const.FrontEndEventTypeUserEnterHall)
  }
})
