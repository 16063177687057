interface newcomerRechargeConf {
  isOpenActivity: boolean // 活动是否开启
  isValidActivity: boolean // 活动是否有效
  newcomerEndTime: number // 新手结束时间
  newcomerRechargeStage: number // 新手充值阶段 -1 表示所有阶段都已完成
  newcomerRechargeFirstLimitEndTime?: number // 限时首充结束时间
  newcomerRechargeFirstLimitRatio?: number // 限时首充最高奖励比例
  newcomerRechargeFirstLimitDiff?: number // 限时首充最高奖励 - 首充最高奖励 = 差值
  newcomerRechargeList: Array<{
    text: string // 文本
    ratio: number // 奖励比例
    status: string // 奖励状态
  }>
}

export const useNewcomerStore = defineStore('newcomerStore', () => {
  
  const { $i18n } = useNuxtApp()

  const rechargeRewardsType = {
    newcomerRechargeFirst : computed(() => $i18n.t('firstDepositText')),
    newcomerRechargeSecond: computed(() => $i18n.t('secondDepositText')),
    newcomerRechargeThird : computed(() => $i18n.t('thirdDepositText')),
    newcomerRechargeFourth: computed(() => $i18n.t('fourthDepositText')),
  }

  const newcomerRechargeConfCache = ref<{ [key: string]: newcomerRechargeConf }>({})

  const newcomerActivityInfo = computed(() => {
    const user = useUserStore()
    const currencyId = String(user.userCurrencyId)
    return newcomerRechargeConfCache.value[currencyId] || null
  })

  const activityNewcomerRechargeInfo = computed(() => {
    if (!newcomerActivityInfo.value) return null

    const { newcomerRechargeList, newcomerRechargeStage } = newcomerActivityInfo.value

    if (newcomerRechargeStage === -1) return null

    return newcomerRechargeList[newcomerRechargeStage]
  })

  async function getActivityNewcomerRechargeConf(currencyId: string, forceRefresh: boolean = false) {
    // const currencyId = String(userCurrencyId.value)
    if (!newcomerRechargeConfCache.value[currencyId] || forceRefresh) {
      const response = await request(activityNewcomerRechargeConf({ currencyId }))
      newcomerRechargeConfCache.value[currencyId] = formatData(response)
    }
  }

  function formatData(data: INewcomerRechargeActivityInfoResp): newcomerRechargeConf {
    const { newcomerRechargeFirstLimit, registerTime, expireSecond } = data || {}

    const result: Partial<newcomerRechargeConf> = { newcomerRechargeStage: 0 }

    // 活动是否开启
    result.isOpenActivity = Object.keys(rechargeRewardsType).every((key, index) => {
      const reward = data?.[key]
      // 存在活动
      if (reward && Object.keys(reward).length > 0) {
        const item = { text: rechargeRewardsType[key].value, ratio: getMaxBonus(reward.bonus), status: reward.status }
        if (result.newcomerRechargeList?.length > 0) {
          result.newcomerRechargeList.push(item)
        } else {
          result.newcomerRechargeList = [item]
        }
        if (reward.status === '1') {
          result.newcomerRechargeStage = index + 1
        }
        return true
      }
      return false
    })

    // -1 表示所有阶段都已完成
    if (result.newcomerRechargeStage > 3) result.newcomerRechargeStage = -1

    // 新手结束时间
    result.newcomerEndTime = registerTime + 1000 * 60 * 60 * 24 * 7
    // 限时首充
    result.newcomerRechargeFirstLimitEndTime = registerTime + Number(expireSecond) * 60000
    // 活动是否有效
    result.isValidActivity = result.newcomerEndTime > Date.now() && result.isOpenActivity

    if (newcomerRechargeFirstLimit && Object.keys(newcomerRechargeFirstLimit).length > 0) {
      result.newcomerRechargeFirstLimitRatio = getMaxBonus(newcomerRechargeFirstLimit.bonus)
      if (result.isOpenActivity) {
        result.newcomerRechargeFirstLimitDiff = Math.max(
          result.newcomerRechargeFirstLimitRatio - result.newcomerRechargeList?.[0].ratio||0,
          0
        )
      }
    }

    return result as newcomerRechargeConf
  }

  function getMaxBonus(bonus: Array<IBonus> = []) {
    let maxBonus = 0
    bonus?.forEach((item) => {
      if (Number(item.bonus) > maxBonus) {
        maxBonus = Number(item.bonus)
      }
    })
    return maxBonus
  }

  /**
   * 充值活动倒计时
   * @param now 当前时间搓
   * @param prompt 完成任务后是否仍然展示倒计时
   * @param type 倒计时类型 first 首充倒计时 end 活动结束倒计时 不传则自动判断
   * @returns 倒数时间
   */
  function getCountDown(now: number, prompt: boolean, type?: 'first' | 'end') {
    // 首充倒计时
    const getFirstCountDown = (countdown: number) => {
      const remainMinutes = Math.max(0, Math.floor(countdown / 60)) // 剩余分钟数
      const remaimSecond = Math.max(0, Math.floor(countdown % 60)) // 剩余秒数
      return `${String(remainMinutes).padStart(2, '0')}:${String(remaimSecond).padStart(2, '0')}`
    }
    // 活动结束倒计时
    const getEndCountDown = (countdown: number) => {
      const remainHours = Math.max(0, Math.floor(countdown / (60 * 60))) // 剩余小时数
      const remainMinutes = Math.max(0, Math.floor((countdown / 60) % 60)) // 剩余分钟数
      const remaimSecond = Math.max(0, Math.floor(countdown % 60)) // 剩余秒数
      return `${String(remainHours).padStart(2, '0')}:${String(remainMinutes).padStart(2, '0')}:${String(remaimSecond).padStart(2, '0')}`
    }

    if (newcomerActivityInfo.value) {
      const { isValidActivity, newcomerRechargeStage, newcomerEndTime, newcomerRechargeFirstLimitEndTime } = newcomerActivityInfo.value

      // 已过期
      if (!isValidActivity) return ''
      
      // 不是一直提示并且所有阶段都已完成
      if (!prompt && newcomerRechargeStage === -1) return ''

      if (type === 'first') {
        return getFirstCountDown(Math.max(0, Math.floor((newcomerRechargeFirstLimitEndTime - now) / 1000)))
      } else if (type === 'end') {
        return getEndCountDown(Math.max(0, Math.floor((newcomerEndTime - now) / 1000)))
      } else {
        if (newcomerRechargeFirstLimitEndTime && newcomerRechargeStage === 0 && newcomerRechargeFirstLimitEndTime > now) {
          return getFirstCountDown(Math.max(0, Math.floor((newcomerRechargeFirstLimitEndTime - now) / 1000)))
        } else {
          return getEndCountDown(Math.max(0, Math.floor((newcomerEndTime - now) / 1000)))
        }
      }
    }

    return ''
  }

  return {
    activityNewcomerRechargeInfo,
    newcomerActivityInfo,

    getActivityNewcomerRechargeConf,
    getCountDown,
  }
})
