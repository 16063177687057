/**
 * 重置store状态
 */
export default defineNuxtPlugin({
  name: 'Initialize',
  setup(nuxtApp) {
    if (process.server) return

    const { isReady } = storeToRefs(useGlobalStore())
    isReady.value = false

    // s2s
    const params: any = getQueryParams()
    if (params.s2s === '1') {
      try {
        setSession(StorageKey.S2S_PARAMS, window.btoa(JSON.stringify(params)))
      } catch (e) {
        Log.error(e)
      }
      // onNuxtReady(() => {
      //   request(msgpushPackageAdjustInfo(params)).catch((e) => {
      //     Log.error(e)
      //   })
      // })
    }
  },
})
