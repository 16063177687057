export default defineNuxtPlugin({
  name: 'DialogHandler',
  setup(nuxtApp) {
    if (process.client) {
      const { $device } = useNuxtApp()
      const { routeQuery } = storeToRefs(useGlobalStore())
      const { isMobileOpenMenu } = storeToRefs(useMenuStore())
      watch(
        () => dialogs.value.length || routeQuery.value.modal || ($device.isMobile && isMobileOpenMenu.value),
        (val) => {
          if (val) {
            document.documentElement.classList.add('noscroll')
          } else {
            document.documentElement.classList.remove('noscroll')
          }
        }
      )

      function handleBack(e) {
        e.preventDefault()
        dialogs.value.pop()
        return false
      }

      window.addEventListener('popstate', handleBack, false)
    }
    nuxtApp.vueApp.provide('dialogs', dialogs)
    nuxtApp.provide('dialogs', dialogs)
  },
})
