export function useLazyData<T>(initValue: any, initFunc: () => Promise<T>): RefreshableRef<T> {
  let status = 0
  const val = ref(initValue)
  const cval: RefreshableRef<T> = computed(() => {
    if (JSON.stringify(val.value) === JSON.stringify(initValue)) {
      if (!status) {
        status = 1
        cval.refresh()
      }
      return initValue
    }
    return val.value
  })
  cval.refresh = async () => {
    val.value = await initFunc()
  }
  return cval
}
